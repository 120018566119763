import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import { useFormik } from "formik";
import {
  subscribeInitialValues,
  subscribeSchema,
} from "@/common/validationSchemas/schemas";
import { SubscribeSchemaValues } from "@/common/types/types";
import { ApiPath, ROOT } from "@/common/enums/apiPath";

export const FeaturedCatbox = (): React.ReactElement => {
  const [message, setMessage] = useState({ text: "", status: "" });
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleViewmore = (): void => {
    navigate("/addpost");
  };

  const handleEvents = (): void => {
    navigate("/events");
  };

  const handleCommunity = (): void => {
    navigate("/communities");
  };

  const handleGarage = (): void => {
    navigate("/");
  };

  const handleJetSki = (): void => {
    navigate("/category/Jet Ski");
  };

  const handleAccessories = (): void => {
    navigate("/category/Accessories");
  };

  const handleApparel = (): void => {
    navigate("/category/Apparel");
  };

  const handleSubscribe = async (
    values: SubscribeSchemaValues,
  ): Promise<void> => {
    setMessage({ text: "", status: "" });

    const requestBody = {
      email: values.email,
    };

    const response = await fetch(`${ROOT}${ApiPath.SUBSCRIBERAPI}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody),
    });

    const { error, data } = await response.json();

    if (data) {
      setMessage({ text: t(data.message), status: "" });
    }

    if (error) setMessage({ text: t(error.message), status: "error" });
  };

  const formik = useFormik({
    initialValues: subscribeInitialValues,
    validationSchema: subscribeSchema,
    onSubmit: (values, { resetForm }) => {
      void handleSubscribe(values);
      resetForm();
    },
  });

  return (
    <Box className={styles.section}>
      <div className="bg-white py-8 rounded-xl max-md:max-w-full">
        <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
          <div className="flex flex-col items-stretch w-3/12 max-md:w-full max-md:ml-0">
            <div
              onClick={handleJetSki}
              className="justify-center items-center bg-white flex grow flex-col w-full px-2.5 py-6 rounded-xl border-[0.5px] border-solid border-sky-500 max-md:mt-10  cursor-pointer transition-all duration-300 ease-in-out hover:bg-yellow-500"
            >
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/95adefd6d67a39f682b636c25d75dbdb15a9412e75e8b10063a5e3780419b71c?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                className="aspect-square object-contain object-center w-20 overflow-hidden max-w-full"
              />
              <div className="self-stretch text-sky-500 text-center text-xl font-semibold whitespace-nowrap mt-4">
                Jet Ski
              </div>
            </div>
          </div>
          <div className="flex flex-col items-stretch w-3/12 ml-5 max-md:w-full max-md:ml-0">
            <div
              onClick={handleAccessories}
              className="justify-center items-center bg-white flex grow flex-col w-full px-2.5 py-6 rounded-xl border-[0.5px] border-solid border-sky-500 max-md:mt-10  cursor-pointer transition-all duration-300 ease-in-out hover:bg-yellow-500"
            >
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/4aa74244e0a30e5898a25a5a326af4d99c227de5e9c29f81d1af9d5dbf6b6aad?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                className="aspect-square object-contain object-center w-20 overflow-hidden max-w-full"
              />
              <div className="self-stretch text-sky-500 text-center text-xl font-semibold whitespace-nowrap mt-4">
                Jet ski Accessories
              </div>
            </div>
          </div>
          <div className="flex flex-col items-stretch w-3/12 ml-5 max-md:w-full max-md:ml-0">
            <div
              onClick={handleApparel}
              className="justify-center items-center bg-white flex grow flex-col w-full px-2.5 py-6 rounded-xl border-[0.5px] border-solid border-sky-500 max-md:mt-10  cursor-pointer transition-all duration-300 ease-in-out hover:bg-yellow-500"
            >
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/e3f1d16e383ddc110a726828825104639330ec4812d7dca41345383d688747bf?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                className="aspect-square object-contain object-center w-20 overflow-hidden max-w-full"
              />
              <div className="self-stretch text-sky-500 text-center text-xl font-semibold whitespace-nowrap mt-4">
                {" "}
                Apparel
              </div>
            </div>
          </div>
          <div className="flex flex-col items-stretch w-3/12 ml-5 max-md:w-full max-md:ml-0">
            <div
              onClick={handleEvents}
              className="justify-center items-center bg-sky-500 flex grow flex-col w-full px-2.5 py-6 rounded-xl border-[0.5px] border-solid border-sky-500 max-md:mt-10  cursor-pointer transition-all duration-300 ease-in-out hover:bg-yellow-500"
            >
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/b2e3b6574bbc0f6d59663d7b5ebda1013f4f8d42d7eb485e8f41d4be42154bf6?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                className="aspect-square object-contain object-center w-20 overflow-hidden max-w-full"
              />
              <div className="self-stretch text-white text-center text-xl font-semibold whitespace-nowrap mt-4">
                Events
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};
