import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import translationEnglish from "./english/translation.json";
import translationArabic from "./arabic/translation.json";

const resources = {
  en: {
    translation: translationEnglish,
  },
  ar: {
    translation: translationArabic,
  },
};

const defaultLanguage = localStorage.getItem("language") ?? "en";

void i18next.use(initReactI18next).init({
  resources,
  lng: defaultLanguage,
});

export default i18next;
