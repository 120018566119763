import React, { useEffect } from "react";
import { Box, Container, Tab, Tabs, Typography } from "@mui/material";
import { useAppSelector, useAppDispatch } from "@/store/hooks";
import clsx from "clsx";
import { fetchOrders } from "@/store/order/slice";
import { OrdersHistory } from "@/components/ordersHistory/OrdersHistory";
import { useTranslation } from "react-i18next";
import { ProfileSettings } from "@/components/profileSettings/ProfileSettings";
import { getDirtyValues, getToken } from "@/helpers/helpers";

import styles from "./styles.module.scss";

export const Profile = (): React.ReactElement => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.user.data);
  const dispatch = useAppDispatch();
  const [value, setValue] = React.useState(0);
  const authToken = getToken() as string;

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: number,
  ): void => {
    setValue(newValue);
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    // dispatch(fetchOrders({userId:user.id,authToken:authToken}));
    const params = { authToken: authToken, userId: user.id };
    void dispatch(fetchOrders(params));
  }, [user.id]);

  return (
    <div className="items-center bg-slate-50 flex flex-col justify-center px-16 py-12 max-md:px-5">
      <div className="items-stretch shadow-sm bg-white flex w-full max-w-[1140px] flex-col my-5 rounded-xl max-md:max-w-full">
        <div className="text-white text-center text-2xl font-medium whitespace-nowrap bg-sky-900 justify-center pl-5 pr-16 py-4 items-start max-md:max-w-full max-md:pr-5 rounded-t-xl">
          My Profile
        </div>
        <div className="p-12 max-md:max-w-full max-md:px-5">
          <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
            <div className="flex flex-col items-stretch w-6/12 max-md:w-full max-md:ml-0">
              <div className="items-stretch self-stretch border bg-white flex grow flex-col w-full p-12 rounded-xl border-solid border-zinc-100 max-md:max-w-full max-md:mt-10 max-md:px-5">
                <div className="flex gap-1.5 py-1 self-start items-start">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/a2d440fa4e2c452175b509e0fefd53fdad6e1a47d1f1d10cd3f4d87401503106?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                    className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
                  />
                  <div className="text-black text-lg font-semibold self-stretch grow whitespace-nowrap">
                    {user.name}
                  </div>
                </div>
                <div className="items-stretch flex justify-between gap-1.5 mt-2.5 py-1.5">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/3f38f737a72b29a5c931befe5eba7bcae6b734a97a4fb4bebf2344114fbae5a1?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                    className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
                  />
                  <div className="text-black text-base grow shrink basis-auto">
                    Location Description, City, Emirates, UAE
                  </div>
                </div>
                <div className="items-stretch flex gap-1.5 mt-2.5 py-1.5 self-start">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/dbe224f0c0de3026dd77a7e692a3781044f4ef4f8c244ff4f413b3cbd083ecab?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                    className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
                  />
                  <div className="text-black text-base grow whitespace-nowrap">
                    +971 123456789
                  </div>
                </div>
                <div className="items-stretch flex gap-1.5 mt-2.5 py-1.5 self-start">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/aadf2d4a466a971dd394001bbbac8e3834d6aa2365e7512099c3eb540bd702ec?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                    className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
                  />
                  <div className="text-black text-base grow whitespace-nowrap">
                    {user.name}
                  </div>
                </div>
                <div className="items-stretch flex justify-between gap-2.5 mt-2.5 mb-32 pt-5 max-md:mb-10">
                  <div className="text-white text-xl font-semibold border bg-sky-500 grow justify-center px-11 py-1.5 rounded-[30px] border-solid border-sky-500 max-md:px-5  text-center cursor-pointer">
                    Edit
                  </div>
                  <div className="text-sky-500 text-xl font-semibold  border bg-white grow justify-center px-11 py-1.5 rounded-[30px] border-solid border-sky-500 max-md:px-5  text-center cursor-pointer">
                    Remove
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-stretch w-6/12 ml-5 max-md:w-full max-md:ml-0">
              <div className="items-stretch self-stretch border bg-white flex grow flex-col w-full pl-12 pr-10 py-12 rounded-xl border-solid border-zinc-100 max-md:max-w-full max-md:mt-10 max-md:px-5">
                <div className="text-black text-lg font-semibold">
                  Change Password
                </div>
                <div className="text-neutral-400 text-base font-medium mt-5">
                  Current Password
                </div>
                <div className="border bg-white flex shrink-0 h-12 flex-col mt-2.5 rounded-md border-solid border-zinc-100" />
                <div className="text-neutral-400 text-base font-medium mt-9">
                  New Password
                </div>
                <div className="border bg-white flex shrink-0 h-12 flex-col mt-2.5 rounded-md border-solid border-zinc-100" />
                <div className="text-neutral-400 text-base font-medium mt-2.5">
                  Confirm Password
                </div>
                <div className="border bg-white flex shrink-0 h-12 flex-col mt-2.5 rounded-md border-solid border-zinc-100" />
                <div className="items-stretch flex justify-between gap-2.5 mt-2.5 pt-5">
                  <div className="text-white text-xl font-semibold items-stretch border bg-sky-500 grow justify-center px-11 py-1.5 rounded-[30px] border-solid border-sky-500 max-md:px-5  text-center cursor-pointer">
                    Save
                  </div>
                  <div className="text-sky-500 text-xl font-semibold items-stretch border bg-white grow justify-center px-11 py-1.5 rounded-[30px] border-solid border-sky-500 max-md:px-5  text-center cursor-pointer">
                    Cancel
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
