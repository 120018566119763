import {
  Box,
  Container,
  Typography,
  Grid,
  Chip,
  Pagination,
} from "@mui/material";
import {
  useParams,
  useLocation,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import { ApiPath, ROOT } from "@enums/apiPath";
import { ProductCard } from "@components/productCard/ProductCard";
import { Filters } from "@/components/filters/Filters";
import { useFetch, useUrlParams } from "@/hooks/hooks";
import { useTranslation } from "react-i18next";
import { Loader } from "@components/general/loader/Loader";
import { CartItem, EventItem } from "@/common/types/types";
import { LazyLoadImage } from "react-lazy-load-image-component";

import styles from "./styles.module.scss";
import { ProductCard2 } from "@/components/productCard/ProductCard2";
import { getProductImage } from "@/helpers/helpers";
import { BlogItem } from "@/common/types/blog/blog";

// interface Event {
//   id: number;
//   name: string;
//   date: number;
//   description: string;
//   image: string;
// }

// const eventsdata: Event[] = [
//   {
//     id: 1,
//     name: "Event 1",
//     date: 1702234145,
//     description: "Event 1 description",
//     image: "",
//   },
//   {
//     id: 2,
//     name: "Event 2",
//     date: 1702234145,
//     description: "Event 2 description",
//     image: "",
//   },
//   {
//     id: 3,
//     name: "Event 3",
//     date: 1702234145,
//     description: "Event 3 description",
//     image: "",
//   },
//   {
//     id: 4,
//     name: "Event 4",
//     date: 1702234145,
//     description: "Event 4 description",
//     image: "",
//   },
//   {
//     id: 5,
//     name: "Event 3",
//     date: 1702234145,
//     description: "Event 5 description",
//     image: "",
//   },
// ];

export const Blogs = (): React.ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { slug } = useParams() as { slug: string };
  const { state } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const { querySizes, queryMaxPrice, querySubcategories } =
    useUrlParams(searchParams);

  const decodedCategory = decodeURIComponent(slug);

  const queryParams = new URL(
    `${ROOT}${ApiPath.BLOGSAPI}&pagination[page]=${page}&pagination[pageSize]=9`,
  );

  querySizes?.forEach((s) => {
    queryParams.searchParams.append("filters[size][$containsi]", s);
  });

  if (queryMaxPrice) {
    queryParams.searchParams.append("filters[price][$lte]", queryMaxPrice);
  }

  querySubcategories?.forEach((s) => {
    queryParams.searchParams.append(
      "filters[$and][0][subcategory][title][$eq]",
      s.charAt(0).toUpperCase() + s.slice(1),
    );
  });

  const { data, meta } = useFetch(queryParams.toString());

  useEffect(() => {
    setPageCount(meta?.pagination?.pageCount);
  }, [data]);

  useEffect(() => {
    setPage(1);
  }, [searchParams]);

  const handleRemoveSizeFilter = (s: string): void => {
    const params: {
      size?: string[];
      maxPrice?: string;
      subcat?: string[];
    } = {};
    if (querySizes) {
      params.size = querySizes.filter((p) => p !== s);
    }

    if (queryMaxPrice) {
      params.maxPrice = queryMaxPrice;
    }

    if (querySubcategories) {
      params.subcat = querySubcategories;
    }

    setSearchParams(params);
  };

  const handleRemovePriceFilter = (p: string): void => {
    const params: {
      size?: string[];
      subcat?: string[];
    } = {};
    if (querySizes) {
      params.size = querySizes;
    }

    if (querySubcategories) {
      params.subcat = querySubcategories;
    }

    setSearchParams(params);
  };

  const handleRemoveSubcategoryFilter = (s: string): void => {
    const params: {
      size?: string[];
      maxPrice?: string;
      subcat?: string[];
    } = {};
    if (querySizes) {
      params.size = querySizes;
    }

    if (queryMaxPrice) {
      params.maxPrice = queryMaxPrice;
    }

    if (querySubcategories) {
      params.subcat = querySubcategories.filter((p) => p !== s);
    }

    setSearchParams(params);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ): void => {
    setPage(value);
  };

  const handleViewmore = (item: BlogItem): void => {
    navigate(ApiPath.BLOGDETAIL, { state: { item } });
  };

  return (
    <Box className={styles.pageContent}>
      <Container>
        <Grid container spacing={4}>
          {slug === "Apparel" && (
            <Grid item md={3} sm={12}>
              <Filters />
            </Grid>
          )}

          <Grid item md={12} sm={12}>
            <div className="text-white text-center text-2xl font-medium whitespace-nowrap bg-sky-900 justify-center pl-5 pr-16 py-4 items-start max-md:max-w-full max-md:pr-5 rounded">
              Blogs
            </div>

            <Grid container spacing={2} mt={2} sx={{ minHeight: "500px" }}>
              {data ? (
                data.map((item: BlogItem) => (
                  <Grid item sm={4} xs={12} key={item.attributes.id}>
                    <Box>
                      <div
                        onClick={() => {
                          handleViewmore(item);
                        }}
                        style={{ cursor: "pointer" }}
                        className="items-stretch bg-white flex max-w-[367px] flex-col rounded-xl  overflow-hidden"
                      >
                        <LazyLoadImage
                          // loading="lazy"
                          src={getProductImage(item.attributes.image)}
                          alt={item.attributes.title}
                          className="aspect-[2.43] object-cover object-center w-full overflow-hidden rounded"
                        />
                        <div className="items-stretch flex w-full flex-col mt-2.5 px-5 py-6">
                          <div className="text-black text-lg font-semibold whitespace-nowrap">
                            {item.attributes.title}
                          </div>
                          <div className="text-stone-500 text-base whitespace-nowrap">
                            {item.attributes.subtitle}
                          </div>
                          <div className="items-stretch flex justify-between gap-2.5 pt-4 pb-6">
                            <LazyLoadImage
                              // loading="lazy"
                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/21b7864987b50d89dae29eb07d455f0fe5b27d0dfce11be2519413aa8aadeaf4?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                              className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
                            />
                            <div className="text-stone-900 text-sm font-semibold grow shrink basis-auto self-start">
                              10-July-2023
                            </div>
                          </div>
                          <div>
                            <div className="relative text-white font-semibold bg-neutral-600 justify-center px-6 py-1 rounded-3xl items-start max-md:px-2 text-center inline-flex">
                              View more
                            </div>
                          </div>
                        </div>
                      </div>
                    </Box>
                  </Grid>
                ))
              ) : (
                <Loader />
              )}
              {data?.length === 0 ? (
                <Box sx={{ minHeight: "500px" }}>
                  <Typography className={styles.notFound}>
                    Nothing found.
                  </Typography>
                </Box>
              ) : null}
            </Grid>
            {pageCount > 1 && (
              <Grid container mt={4} className={styles.paginationContainer}>
                <Pagination
                  count={pageCount}
                  page={page}
                  onChange={handlePageChange}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
