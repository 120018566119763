import {
  Box,
  Container,
  Typography,
  Grid,
  Chip,
  Pagination,
} from "@mui/material";
import {
  useParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import { ApiPath, ROOT } from "@enums/apiPath";
import { ProductCard } from "@components/productCard/ProductCard";
import { Filters } from "@/components/filters/Filters";
import { useFetch, useUrlParams } from "@/hooks/hooks";
import { useTranslation } from "react-i18next";
import { Loader } from "@components/general/loader/Loader";
import { CartItem, EventItem } from "@/common/types/types";

import styles from "./styles.module.scss";
import { ProductCard2 } from "@/components/productCard/ProductCard2";
import { getProductImage } from "@/helpers/helpers";
import { BlogItem } from "@/common/types/blog/blog";

export const HowItWorks = (): React.ReactElement => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  // Access the 'item' data from the previous route
  const item: BlogItem = state?.item;

  const handleGoBack = (): void => {
    navigate(-1);
  };

  const processDate = (dval: string): string => {
    const dateObject = new Date(dval);

    // Check if the date is valid
    if (!isNaN(dateObject.getTime())) {
      // Format the date as a string (e.g., "2023-01-01 12:00 PM")
      const formattedDateString = dateObject.toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });

      return formattedDateString;
    } else {
      // Handle invalid date string
      // console.error('Invalid date-time string:', dateTimeString);
      return "Invalid Date'";
    }
  };

  return (
    <div className="items-center self-stretch bg-slate-50 flex flex-col justify-center px-16 py-12 max-md:px-5 rounded-2xl overflow-hidden">
      <div className="flex w-full max-w-[1140px] flex-col items-stretch my-8 max-md:max-w-full rounded-2xl overflow-hidden">
        <img
          // loading="eager"
          src={
            "https://images.pexels.com/photos/13942553/pexels-photo-13942553.jpeg?auto=compress&cs=tinysrgb&w=800"
          }
          alt={"Yifeenah"}
          className="aspect-[3.06] object-cover object-center w-full justify-end items-start overflow-hidden max-md:max-w-full"
        />
        <div className="items-start bg-white flex flex-col pt-5 pb-10 px-8 rounded-none max-md:max-w-full max-md:px-5">
          <div className="self-stretch text-neutral-800 text-2xl font-semibold whitespace-nowrap max-md:max-w-full">
            HOW IT WORKS
          </div>
          <div className="self-stretch text-neutral-800 text-lg mt-4 max-md:max-w-full">
            <header>
              <h1>Welcome to our Jet Ski Selling Platform!</h1>
            </header>

            <section>
              <h2>Jet Ski Posting Made Easy:</h2>
              <p>
                Our user-friendly interface empowers sellers to effortlessly
                post detailed listings for their jet skis. Simply navigate
                through the intuitive posting process, providing essential
                details about your watercraft, including its make, model, year,
                condition, and any exciting features that make it stand out.
              </p>
            </section>

            <section>
              <h2>Free and Premium Plans:</h2>
              <p>
                We believe in offering flexibility, which is why we provide both
                free and premium plans to cater to a variety of sellers. Choose
                the plan that best aligns with your needs:
              </p>
              <ul>
                <li>
                  <strong>Free Plan:</strong>
                  <ul>
                    <li>Basic jet ski listing.</li>
                    <li>Reach a wide audience of potential buyers.</li>
                    <li>Perfect for those who want to test the waters.</li>
                  </ul>
                </li>
                <li>
                  <strong>Premium Plan:</strong>
                  <ul>
                    <li>Featured placement for maximum visibility.</li>
                    <li>
                      Enhanced listing with additional images and video options.
                    </li>
                    <li>Priority access to our dedicated support team.</li>
                    <li>
                      Ideal for sellers seeking a competitive edge in the
                      market.
                    </li>
                  </ul>
                </li>
              </ul>
            </section>

            <section>
              <h2>Maximize Exposure:</h2>
              <p>
                Gain exposure in our vibrant community of jet ski enthusiasts.
                Premium plan listings are highlighted, ensuring they catch the
                eye of potential buyers. Leverage our platforms reach to connect
                with individuals who share your passion for water sports.
              </p>
            </section>

            <section>
              <h2>Secure Transactions:</h2>
              <p>
                Our platform prioritizes the safety and security of every
                transaction. Benefit from a secure environment that facilitates
                communication between buyers and sellers, making the process of
                selling or purchasing a jet ski a breeze.
              </p>
            </section>

            <section>
              <h2>Community Engagement:</h2>
              <p>
                Join a thriving community where jet ski enthusiasts share
                experiences, tips, and recommendations. Connect with like-minded
                individuals, stay updated on the latest trends, and foster a
                sense of camaraderie within our dedicated community space.
              </p>
            </section>

            <section>
              <h2>Your Jet Ski Adventure Begins Here:</h2>
              <p>
                Whether you are a seasoned seller or a first-time buyer, our jet
                ski selling platform provides the tools and resources you need.
                Start your jet ski adventure today – post your listing, explore
                our vibrant community, and make waves in the world of water
                sports!
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};
