import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Link, useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import { useFormik } from "formik";
import { FeaturedSliderSkeleton } from "./FeaturedSliderSkeleton";
import { useFetch } from "@/hooks/hooks";
import {
  subscribeInitialValues,
  subscribeSchema,
} from "@/common/validationSchemas/schemas";
import { CartItem, SubscribeSchemaValues } from "@/common/types/types";
import { ApiPath, ROOT } from "@/common/enums/apiPath";
import { getProductImage } from "@/helpers/helpers";
import { ProductCard } from "../productCard/ProductCard";
import { ProductCard2 } from "@/components/productCard/ProductCard2";
import { Loader } from "@components/general/loader/Loader";

export const FeaturedProductbox = (): React.ReactElement => {
  const [message, setMessage] = useState({ text: "", status: "" });
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, loading, error } = useFetch(
    `${ROOT}${ApiPath.ITEMSAPI}&filters[type][$eq]=featured&filters[category][$eq]=Jet Ski`,
  );

  const handleViewmore = (): void => {
    navigate("/category/Jet Ski&filters[type][$eq]=featured", {
      state: { title: "Featured" },
    });
  };

  const handleSubscribe = async (
    values: SubscribeSchemaValues,
  ): Promise<void> => {
    setMessage({ text: "", status: "" });

    const requestBody = {
      email: values.email,
    };

    const response = await fetch(`${ROOT}${ApiPath.SUBSCRIBERAPI}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody),
    });

    const { error, data } = await response.json();

    if (data) {
      setMessage({ text: t(data.message), status: "" });
    }

    if (error) setMessage({ text: t(error.message), status: "error" });
  };

  const formik = useFormik({
    initialValues: subscribeInitialValues,
    validationSchema: subscribeSchema,
    onSubmit: (values, { resetForm }) => {
      void handleSubscribe(values);
      resetForm();
    },
  });

  return (
    <Box className={styles.section}>
      <div className="items-stretch bg-stone-50 flex flex-col pt-5 px-5 max-md:max-w-full">
        <div className="items-stretch flex justify-between gap-2.5 pl-2.5 pt-2.5 max-md:max-w-full max-md:flex-wrap">
          <div className="text-stone-900 text-2xl font-semibold whitespace-nowrap max-md:max-w-full">
            Featured Listings
          </div>
          <div
            onClick={handleViewmore}
            className="text-white text-base font-semibold whitespace-nowrap justify-center items-stretch bg-neutral-700 px-6 py-1 rounded-[30px] self-start max-md:px-5  cursor-pointer transition-all duration-300 ease-in-out hover:bg-yellow-500"
          >
            View All
          </div>
        </div>
        <div className="mt-2.5 pb-6 max-md:max-w-full">
          <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
            {loading ? (
              <FeaturedSliderSkeleton />
            ) : (
              <Grid container spacing={2} mt={2}>
                {data ? (
                  data.map((item: CartItem) => (
                    <Grid item sm={3} xs={12} key={item.id}>
                      <ProductCard item={item} />
                    </Grid>
                  ))
                ) : (
                  <Loader />
                )}
                {data?.length === 0 ? (
                  <Typography className={styles.notFound}>
                    Nothing found.
                  </Typography>
                ) : null}
              </Grid>

              // <Swiper
              //   modules={[Navigation, Autoplay]}
              //   spaceBetween={0}
              //   slidesPerView={1}
              //   navigation={{
              //     nextEl: ".swiper-button-next",
              //     prevEl: ".swiper-button-prev",
              //     disabledClass: "swiper-button-disabled",
              //   }}
              //   loop={false}
              //   breakpoints={{
              //     600: {
              //       slidesPerView: 2,
              //       spaceBetween: 15,
              //     },
              //     900: {
              //       slidesPerView: 4,
              //       spaceBetween: 15,
              //     },
              //   }}
              //   className={styles.carousel}
              // >
              //   {data?.map((slide: CartItem, index: number) => (
              //     <SwiperSlide
              //       key={index}
              //       // onClick={() => navigate(`/item/${slide?.attributes?.slug}`)}
              //     >
              //       <ProductCard item={slide} />
              //       {/* <div className="flex flex-col items-stretch max-md:w-full max-md:ml-0">
              //         <div className="justify-center items-stretch shadow-sm bg-white flex w-full grow flex-col mx-auto pt-2.5 pb-5 px-2.5 max-md:mt-2.5">
              //           <img
              //             loading="lazy"
              //             src={getProductImage(slide.attributes.image)}
              //             alt=""
              //             // srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/dfac4e603d2342b98772f71dfff4e138052ded07850f1702f6d44b299ccde1ad?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/dfac4e603d2342b98772f71dfff4e138052ded07850f1702f6d44b299ccde1ad?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/dfac4e603d2342b98772f71dfff4e138052ded07850f1702f6d44b299ccde1ad?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/dfac4e603d2342b98772f71dfff4e138052ded07850f1702f6d44b299ccde1ad?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/dfac4e603d2342b98772f71dfff4e138052ded07850f1702f6d44b299ccde1ad?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/dfac4e603d2342b98772f71dfff4e138052ded07850f1702f6d44b299ccde1ad?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/dfac4e603d2342b98772f71dfff4e138052ded07850f1702f6d44b299ccde1ad?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/dfac4e603d2342b98772f71dfff4e138052ded07850f1702f6d44b299ccde1ad?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
              //             className="aspect-[1.81] object-contain object-center w-full overflow-hidden  transition-transform duration-300 transform hover:scale-110"
              //           />
              //           <div className="text-black text-base whitespace-nowrap mt-2 text-left">
              //             {slide.attributes.name}
              //           </div>
              //           <div className="text-black text-sm font-bold whitespace-nowrap  text-left">
              //             AED {slide.attributes.price}
              //           </div>
              //           <div className="items-center flex  gap-1.5 mt-4">
              //             <img
              //               loading="lazy"
              //               src="https://cdn.builder.io/api/v1/image/assets/TEMP/189818d4136500ea5ed1babe213422c3cdb90fe6fe6b33bd7c19d0e418897a99?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
              //               className="aspect-square object-contain object-center w-5 overflow-hidden shrink-0 max-w-full my-auto"
              //             />
              //             <div className="text-black text-base  text-left">
              //               4 Years
              //             </div>
              //           </div>
              //           <div className="flex  gap-1.5 py-3 items-start">
              //             <img
              //               loading="lazy"
              //               src="https://cdn.builder.io/api/v1/image/assets/TEMP/55971f735487eb17bfe9a198fc311e96a6f36692cd570c0e98f59a03254953df?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
              //               className="aspect-square object-contain object-center w-5 overflow-hidden shrink-0 max-w-full"
              //             />
              //             <div className="text-black text-base  text-left">
              //               Abu Dhabi
              //             </div>
              //           </div>
              //           <div className="text-white text-base font-semibold whitespace-nowrap items-center border bg-sky-500 mt-1.5 py-1.5 rounded-[30px] border-solid border-sky-500 max-md:px-5   cursor-pointer transition-all duration-300 ease-in-out hover:bg-yellow-500">
              //             View more
              //           </div>
              //         </div>
              //         {slide.attributes.type === "featured" && (
              //           <Box
              //             sx={{
              //               position: "absolute",
              //               left: "8px",
              //               top: "8px",
              //               backgroundColor: "#FCEDDA", // "#00539C",
              //               paddingRight: "8px",
              //               paddingLeft: "8px",
              //               borderRadius: "16px",
              //               alignItems: "center",
              //             }}
              //           >
              //             <Typography
              //               color={"#17A6FF"}
              //               sx={{ fontWeight: "900px" }}
              //               variant="caption"
              //             >
              //               Featured
              //             </Typography>
              //           </Box>
              //         )}
              //       </div> */}
              //     </SwiperSlide>
              //   ))}
              // </Swiper>
            )}
          </div>
        </div>
      </div>
    </Box>
  );
};
