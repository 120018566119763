import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { ApiPath, ROOT } from "@enums/apiPath";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Badge,
} from "@mui/material";
import { formatPrice, getProductImage } from "@helpers/helpers";
import { Quantity } from "@/components/general/quantity/Quantity";
import { v4 as uuidv4 } from "uuid";
import { addToCartById } from "@/store/cart/slice";
import { useTranslation } from "react-i18next";
import { useFetch } from "@/hooks/hooks";
import clsx from "clsx";
import { Loader } from "@components/general/loader/Loader";

import styles from "./styles.module.scss";
import { ProductsListSlider } from "@/components/productsListSlider/productsListSlider";
import { ProductsSlider } from "@/components/productsSlider/ProductsSlider";

export const Product = (): React.ReactElement => {
  const { t } = useTranslation();
  const { slug } = useParams() as { slug: string };
  const dispatch = useAppDispatch();
  const cart = useAppSelector((state) => state.cart.cart);
  const [count, setCount] = useState(1);
  const [viewcontact, setViewcontact] = useState(false);
  const [size, setSize] = useState("");
  const [error, setError] = useState<string>();

  const {
    data: data2,
    loading: loading2,
    error: error2,
  } = useFetch(`${ROOT}${ApiPath.ITEMSAPI}&filters[category][$eq]=Jet Ski`);

  const {
    data: item,
    loading,
    error: requestErr,
  } = useFetch(`${ROOT}${ApiPath.ITEMSAPI}&filters[slug][$eq]=${slug}`);

  const descreaseCount = (): void => {
    setCount(Math.max(count - 1, 1));
  };

  const increaseCount = (): void => {
    setCount(count + 1);
  };

  const handleSizeChange = (
    event: React.MouseEvent<HTMLElement>,
    size: string,
  ): void => {
    setSize(size);
  };

  const handleAddToCart = (): void => {
    console.log("Adding to cart!");
    if (!item) return;
    if (item[0].attributes.size) {
      if (!size) {
        setError(t("chooseSizeError"));
        return;
      } else {
        setError("");
      }
    }
    dispatch(
      addToCartById({
        id: item[0].id,
        count,
        size,
        price: item[0].attributes.price,
        sku: item[0].attributes.sku,
      }),
    );
  };

  const handleViewContact = (): void => {
    if (!item) return;
    setViewcontact(!viewcontact);
  };

  const handleShare = (): void => {
    if (!item) return;
    handleViewContact();
  };

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cart));
  }, [cart]);

  if (requestErr) return <p>Error</p>;

  if (loading) return <Loader />;

  return (
    <>
      {item && (
        <Box className={clsx("page", styles.section)}>
          <Container>
            <Box
              className={styles.stepbox}
              sx={{ boxShadow: 1, paddingBottom: "10px", marginBottom: "24px" }}
            >
              <Box
                display={"flex"}
                flexDirection={"row"}
                className={styles.stepboxheader}
              >
                <Typography
                  variant="h6"
                  className={styles.pageTitle}
                  sx={{ flex: 1 }}
                >
                  {item[0].attributes.name}
                </Typography>

                <Typography variant="h6" className={styles.pageTitle}>
                  AED {item[0].attributes.price}
                </Typography>
              </Box>

              <Grid container spacing={8}>
                {item[0].attributes.image.data && (
                  <Grid
                    item
                    sm={5}
                    xs={12}
                    className={styles.desktopImgContainer}
                  >
                    <img
                      src={getProductImage(item[0].attributes.image)}
                      alt={item[0].attributes.name}
                      className={styles.mainImage}
                      style={{ width: "100%" }}
                      // className="aspect-[1.34] object-contain object-center w-[111px] overflow-hidden"
                    />
                  </Grid>
                )}
                <Grid item sm={7} xs={12}>
                  <div className="justify-center border shadow-sm flex grow flex-col w-full pl-8 pr-20 py-8 rounded-xl border-solid border-zinc-100 items-start max-md:max-w-full max-md:mt-10 max-md:px-5 mt-3 mr-3">
                    <div className="justify-center flex gap-3 pt-2.5 items-start">
                      <div className="text-black text-base">Posted By :</div>
                      <div className="text-black text-xl font-bold">
                        User2020
                      </div>
                    </div>
                    <div className="justify-end items-stretch flex w-40 max-w-full gap-2.5 mt-1.5 pt-2.5 pb-8 rounded-md max-md:justify-center">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/367d95dd5965e7e0e4a8377e5d73dcb81fd9e45c0dd77de7df69df81ac0210a1?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                        className="aspect-square object-contain object-center w-full overflow-hidden shrink-0 flex-1"
                      />
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/367d95dd5965e7e0e4a8377e5d73dcb81fd9e45c0dd77de7df69df81ac0210a1?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                        className="aspect-square object-contain object-center w-full overflow-hidden shrink-0 flex-1"
                      />
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/367d95dd5965e7e0e4a8377e5d73dcb81fd9e45c0dd77de7df69df81ac0210a1?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                        className="aspect-square object-contain object-center w-full overflow-hidden shrink-0 flex-1"
                      />
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/367d95dd5965e7e0e4a8377e5d73dcb81fd9e45c0dd77de7df69df81ac0210a1?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                        className="aspect-square object-contain object-center w-full overflow-hidden shrink-0 flex-1"
                      />
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/06c9c20e8f8bc84724f9bcb712107eb436bc00a63806c6e47229d98163a70aa7?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                        className="aspect-square object-contain object-center w-full overflow-hidden shrink-0 flex-1"
                      />
                    </div>
                    <div className="items-stretch flex gap-2.5 mt-1.5 pr-9 pt-10 pb-2.5 max-md:pr-5">
                      <div className="flex justify-between gap-1.5 pb-2.5 items-start">
                        <img
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/7d5ddf9e1e864c62dad066c288122c372a7594ffe1960c7da8d8c8fc8faa4209?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                          className="aspect-square object-contain object-center w-5 overflow-hidden shrink-0 max-w-full"
                        />
                        <div className="text-black text-base">4 Years</div>
                      </div>
                      <div className="flex justify-between gap-1.5 pl-8 pb-2.5 items-start max-md:pl-5">
                        <img
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/9bf9e9a0a2ac7c7eadbe1c3eca5c4d48d81d9c7645502c8cff5f20cef419d362?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                          className="aspect-square object-contain object-center w-5 overflow-hidden shrink-0 max-w-full"
                        />
                        <div className="text-black text-base">Abu Dhabi</div>
                      </div>
                    </div>
                    <div className="items-stretch flex w-full flex-col mt-1.5 pl-2.5 pr-20 pt-2.5 pb-6 max-md:pr-5">
                      <div className="justify-between items-stretch flex gap-3 pt-2.5">
                        <div className="text-black text-base grow whitespace-nowrap">
                          Brand
                        </div>
                        <div className="text-black text-sm font-bold grow whitespace-nowrap self-start">
                          Jet ski Brand
                        </div>
                      </div>
                      <div className="justify-between items-stretch flex gap-3 pt-2.5">
                        <div className="text-black text-base grow whitespace-nowrap">
                          Warranty
                        </div>
                        <div className="text-black text-sm font-bold grow whitespace-nowrap self-start">
                          Warranty Status
                        </div>
                      </div>
                      <div className="justify-between items-stretch flex gap-3 pt-2.5">
                        <div className="text-black text-base grow whitespace-nowrap">
                          Condition
                        </div>
                        <div className="text-black text-sm font-bold grow whitespace-nowrap self-start">
                          Jet ski Condition
                        </div>
                      </div>
                    </div>
                    <div className="text-neutral-400 text-lg font-medium whitespace-nowrap mt-1.5">
                      {" "}
                      03/10 Views
                    </div>

                    {viewcontact && (
                      <div className="space-y-2 p-4 mb-5 bg-white rounded-md shadow-md">
                        <p className="text-lg font-medium">
                          Email: to.apptester@gmail.com
                        </p>
                        <p className="text-lg font-medium">
                          Phone: +971-55-234-5454
                        </p>
                      </div>
                    )}

                    <div className="flex flex-col md:flex-row items-stretch gap-2.5 mt-1.5">
                      <div
                        onClick={handleViewContact}
                        className="justify-between items-stretch bg-amber-400 flex gap-4 px-4 py-1.5 rounded-[30px] cursor-pointer transition-all duration-300 ease-in-out hover:bg-yellow-500"
                      >
                        <img
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/bec2a969177aa1623bedb15329a3c15568cf626baf96206073e96d7e681b588b?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                          className="aspect-square object-contain object-center w-[30px] overflow-hidden shrink-0 max-w-full"
                        />
                        <div className="text-white w-full text-base font-semibold self-center grow whitespace-nowrap my-auto">
                          {viewcontact ? t("hide") : t("viewcontact")}
                        </div>
                      </div>
                      <div className="justify-between items-stretch bg-sky-500 flex gap-4 px-12 py-1.5 rounded-[30px] max-md:px-5 cursor-pointer transition-all duration-300 ease-in-out hover:bg-yellow-500">
                        <img
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/f962fd191e6fed3e8ca5cd559ffd33abb8b70fef425ecd43d031363b27add3d2?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                          className="aspect-square object-contain object-center w-[30px] overflow-hidden shrink-0 max-w-full"
                        />
                        <div className="text-white  w-full text-base font-semibold self-center my-auto">
                          {t("share")}
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col md:flex-row items-stretch gap-2.5 mt-1.5 pb-5">
                      {item[0].attributes.category === "Apparel" && (
                        <div
                          onClick={handleAddToCart}
                          className="justify-center items-center border bg-neutral-500 flex w-full md:w-96 flex-col mt-1.5 px-16 py-1.5 rounded-[30px] border-solid border-sky-500 max-md:px-5 cursor-pointer transition-all duration-300 ease-in-out hover:bg-yellow-500"
                        >
                          <div className="flex items-stretch gap-4">
                            <img
                              loading="lazy"
                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/a5b42cbf90d59cb876f4211526693b2b4a1a719318576c94338d164f2e5f67f4?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                              className="aspect-square object-contain object-center w-[30px] overflow-hidden shrink-0 max-w-full"
                            />
                            <div className="text-white text-base font-semibold self-center my-auto">
                              Add to Cart
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* <div className="items-stretch flex gap-2.5 mt-1.5 pb-5">
                      <div
                        onClick={handleViewContact}
                        className="justify-between items-stretch bg-amber-400 flex gap-4 px-4 py-1.5 rounded-[30px]     cursor-pointer transition-all duration-300 ease-in-out hover:bg-yellow-500"
                      >
                        <img
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/bec2a969177aa1623bedb15329a3c15568cf626baf96206073e96d7e681b588b?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                          className="aspect-square object-contain object-center w-[30px] overflow-hidden shrink-0 max-w-full"
                        />
                        <div className="text-white text-base font-semibold self-center grow whitespace-nowrap my-auto">
                          {viewcontact ? t("hide") : t("viewcontact")}
                        </div>
                      </div>
                      <div className="justify-between items-stretch bg-sky-500 flex gap-4 px-12 py-1.5 rounded-[30px] max-md:px-5  cursor-pointer  transition-all duration-300 ease-in-out hover:bg-yellow-500">
                        <img
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/f962fd191e6fed3e8ca5cd559ffd33abb8b70fef425ecd43d031363b27add3d2?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                          className="aspect-square object-contain object-center w-[30px] overflow-hidden shrink-0 max-w-full"
                        />
                        <div className="text-white text-base font-semibold self-center my-auto">
                          {t("share")}
                        </div>
                      </div>
                    </div> */}
                    {/* 
                    {item[0].attributes.category === "Apparel" && (
                      <div
                        onClick={handleAddToCart}
                        className="justify-center items-center border bg-neutral-500 flex w-96 flex-col mt-1.5 px-16 py-1.5 rounded-[30px] border-solid border-sky-500 max-md:px-5  cursor-pointer  transition-all duration-300 ease-in-out hover:bg-yellow-500"
                      >
                        <div className="flex items-stretch gap-4">
                          <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/a5b42cbf90d59cb876f4211526693b2b4a1a719318576c94338d164f2e5f67f4?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                            className="aspect-square object-contain object-center w-[30px] overflow-hidden shrink-0 max-w-full"
                          />
                          <div className="text-white text-base font-semibold self-center my-auto">
                            Add to Cart
                          </div>
                        </div>
                      </div>
                    )} */}
                  </div>
                </Grid>
              </Grid>
            </Box>

            <ProductsSlider
              title={"People also viewed this."}
              category={"Jet Ski"}
              slides={data2}
              loading={loading2}
            />
          </Container>
        </Box>
      )}
    </>
  );
};
