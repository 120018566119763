import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import { Link, useNavigate } from "react-router-dom";
import {
  AppBar,
  Badge,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  IconButton,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { setIsCartOpen } from "@store/cart/slice";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { MiniCart } from "../miniCart/miniCart";
import { ApiPath } from "@/common/enums/apiPath";
import { fetchUser, logout } from "@store/user/slice";
import { getToken, removeToken } from "@/helpers/helpers";
import { AvatarMenu } from "../avatarMenu/AvatarMenu";
import { SearchBar } from "@/components/header/searchBar/SearchBar";
import { useTranslation } from "react-i18next";
import { useIsMobile, useScrollBlock } from "@/hooks/hooks";

import styles from "./styles.module.scss";

export const MiniHeader = (): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.data);
  const cart = useAppSelector((state) => state.cart.cart);
  const { blockScroll, allowScroll } = useScrollBlock();
  const navigate = useNavigate();
  const { isMobile } = useIsMobile(900);

  const authToken = getToken();

  const rightMenuPages = [
    {
      label: t("events"),
      path: "/events",
    },
    {
      label: t("community"),
      path: "/communities",
    },
    {
      label: t("blog"),
      path: "/blogs",
    },
  ];

  const topLefttMenuPages = [
    {
      label: t("Notifications"),
      path: "/about",
    },
    {
      label: t("Favorites"),
      path: "/contact",
    },
  ];
  const handleViewmore = (): void => {
    navigate("/addpost");
  };

  const [menuOpened, setMenuOpened] = useState(false);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setMenuOpened(!menuOpened);
    if (menuOpened) {
      allowScroll();
    } else {
      blockScroll();
    }
  };

  const handleLogout = (): void => {
    removeToken();
    dispatch(logout());
    navigate("/");
  };

  useEffect(() => {
    if (authToken) {
      void dispatch(fetchUser(authToken));
    }
  }, [authToken]);

  useEffect(() => {
    if (!isMobile) setMenuOpened(false);
  }, [isMobile]);

  return (
    <>
      <AppBar
        position="sticky"
        sx={{
          bgcolor: "#A2DBFF",
          height: "90px",
          paddingRight: "40px",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 20,
        }}
        className={styles.header}
      >
        <Container>
          <Toolbar disableGutters={true} className={styles.toolbar}>
            <div className={styles.centerCol}>
              <Link to="/" className={styles.siteLogo}>
                <Typography
                  variant="h3"
                  component="h1"
                  className={styles.siteLogoText}
                >
                  Yifeenah
                </Typography>
              </Link>
            </div>
            <div className={styles.rightCol}>
              <Box sx={{ display: { xs: "none", md: "block" } }}>
                <div className={styles.menu}>
                  {rightMenuPages.map(({ label, path }) => (
                    <Link to={path} className="link" key={label}>
                      <Typography sx={{ color: "#fff" }}>{label}</Typography>
                    </Link>
                  ))}
                </div>
              </Box>
              <div className={styles.icons}>
                <SearchBar />

                <Box className={styles.cardButtonWrapper}>
                  <Button
                    sx={{
                      backgroundColor: "#17A6FF",
                      color: "white",
                      borderRadius: 30,
                      height: "36px",
                      padding: isMobile ? "5px 5px" : "5px 10px",
                      fontSize: "12px",
                      fontWeight: "500",
                      width: isMobile ? "36px" : "100px",
                      "&:hover": {
                        backgroundColor: "#D3A56B",
                      },
                    }}
                    onClick={handleViewmore}
                  >
                    {isMobile ? "+" : t("addpost")}
                  </Button>
                </Box>
                {/* <Box sx={{ display: { xs: "none", md: "block" } }}>
                  {!user.name && (
                    <Link to={ApiPath.SIGNIN} className={styles.link}>
                      <PersonOutlineIcon />
                    </Link>
                  )}
                  {user.name && <AvatarMenu />}
                </Box> */}
                {/* TODO:
                <FavoriteBorderIcon /> */}
                <IconButton
                  onClick={() => dispatch(setIsCartOpen())}
                  sx={{ color: "white" }}
                >
                  <Badge badgeContent={cart.length} color="error">
                    <ShoppingBasketIcon />
                  </Badge>
                </IconButton>
              </div>
            </div>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};
