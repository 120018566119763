import {
  Box,
  Container,
  Typography,
  Grid,
  Chip,
  Pagination,
} from "@mui/material";
import {
  useParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import { ApiPath, ROOT } from "@enums/apiPath";
import { ProductCard } from "@components/productCard/ProductCard";
import { Filters } from "@/components/filters/Filters";
import { useFetch, useUrlParams } from "@/hooks/hooks";
import { useTranslation } from "react-i18next";
import { Loader } from "@components/general/loader/Loader";
import { CartItem, EventItem } from "@/common/types/types";
import { LazyLoadImage } from "react-lazy-load-image-component";

import styles from "./styles.module.scss";
import { ProductCard2 } from "@/components/productCard/ProductCard2";
import { getProductImage } from "@/helpers/helpers";

export const EventDetail = (): React.ReactElement => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  // Access the 'item' data from the previous route
  const item: EventItem = state?.item;

  const handleGoBack = (): void => {
    navigate(-1);
  };

  const processDate = (dval: string): string => {
    const dateObject = new Date(dval);

    // Check if the date is valid
    if (!isNaN(dateObject.getTime())) {
      // Format the date as a string (e.g., "2023-01-01 12:00 PM")
      const formattedDateString = dateObject.toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });

      return formattedDateString;
    } else {
      // Handle invalid date string
      // console.error('Invalid date-time string:', dateTimeString);
      return "Invalid Date'";
    }
  };

  return (
    <>
      <div className="items-center self-stretch bg-slate-50 flex flex-col justify-center px-16 py-12 max-md:px-5  rounded-2xl overflow-hidden">
        <div className="flex w-full max-w-[1140px] flex-col items-stretch my-8 max-md:max-w-full  rounded-2xl overflow-hidden">
          <LazyLoadImage
            // loading="lazy"
            src={getProductImage(item.attributes.image)}
            alt={item.attributes.name}
            className="aspect-[3.06] object-cover object-center w-full justify-end items-start overflow-hidden max-md:max-w-full"
          />
          <div className="items-start bg-white flex flex-col pt-5 pb-10 px-8 rounded-none max-md:max-w-full max-md:px-5">
            <div className="items-stretch self-stretch flex justify-between gap-5 pr-20 pt-2.5 pb-8 max-md:max-w-full max-md:flex-wrap max-md:pr-5">
              <div className="items-stretch flex justify-between gap-2.5">
                <LazyLoadImage
                  // loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/3d34b5bf4b5bcf752fe7979fad06b976efb00c2b327f9eb0d93c3ac982cd14e5?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                  className="aspect-square object-contain object-center w-[30px] overflow-hidden shrink-0 max-w-full"
                />
                <div className="text-stone-900 text-lg font-semibold self-center grow whitespace-nowrap my-auto">
                  {processDate(item.attributes.updatedAt)}
                </div>
              </div>
              <div className="items-stretch flex justify-between gap-2.5">
                <LazyLoadImage
                  // loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/bb18484c495d746b8f59779be958a4c582f2c240cd69533e7ad50564006b314d?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                  className="aspect-square object-contain object-center w-[30px] overflow-hidden shrink-0 max-w-full"
                />
                <div className="text-stone-900 text-lg font-semibold self-center grow whitespace-nowrap my-auto">
                  6.00-8.00 am
                </div>
              </div>
              <div className="items-stretch flex justify-between gap-2.5">
                <LazyLoadImage
                  // loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/2b481597bcba5f228793e5a3a462a8775e0e1f3e4f522a47727406c962075e7e?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                  className="aspect-square object-contain object-center w-[30px] overflow-hidden shrink-0 max-w-full"
                />
                <div className="text-stone-900 text-lg font-semibold self-center grow whitespace-nowrap my-auto">
                  Abu Dhabi
                </div>
              </div>
            </div>
            <div className="self-stretch text-neutral-800 text-2xl font-semibold whitespace-nowrap mt-4 max-md:max-w-full">
              {item.attributes.name}
            </div>
            <div className="self-stretch text-neutral-800 text-lg mt-4 max-md:max-w-full">
              {item.attributes.description}
            </div>
            <div className="self-stretch mt-4 py-11 max-md:max-w-full max-md:pr-5">
              <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                <div className="flex flex-col  max-md:w-full max-md:ml-0">
                  <div className="items-stretch bg-green-400 flex gap-2.5 w-full px-6 py-2 rounded-3xl max-md:mt-10 max-md:px-5">
                    <LazyLoadImage
                      // loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/9f8f2a923e6b19dfc43960eabfd96a7b05c51902b4fac381aaddc79418180396?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                      className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
                    />
                    <div className="text-white text-base font-semibold grow whitespace-nowrap self-start">
                      Chat with us
                    </div>
                  </div>
                </div>
                <div className="flex flex-col ml-5 max-md:w-full max-md:ml-0">
                  <div className="items-stretch bg-zinc-600 flex gap-2.5 w-full px-6 py-2 rounded-3xl max-md:mt-10 max-md:px-5">
                    <LazyLoadImage
                      // loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/0decf32b9a707ed74fe934cca989e818e474f166afd34105bc26cdb2dd96d8c5?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                      className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
                    />
                    <div className="text-white text-base font-semibold self-start">
                      Call Us
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="items-stretch flex gap-2.5 mt-4 pr-6 py-1.5 rounded-3xl self-start max-md:pr-5">
              <LazyLoadImage
                // loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/b6ae62003a07dfcb3c295739c612f3a2fb5294e8a7321419ca76bec02ee99b5d?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                className="aspect-square object-contain object-center w-[30px] overflow-hidden shrink-0 max-w-full"
              />
              <div
                onClick={() => {
                  handleGoBack();
                }}
                className="text-sky-500 text-base font-semibold self-center grow whitespace-nowrap my-auto"
                style={{ cursor: "pointer" }}
              >
                Back to event list
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
