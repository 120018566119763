import {
  Box,
  Container,
  Typography,
  Grid,
  Chip,
  Pagination,
} from "@mui/material";
import { useParams, useLocation, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { ApiPath, ROOT } from "@enums/apiPath";
import { ProductCard } from "@components/productCard/ProductCard";
import { Filters } from "@/components/filters/Filters";
import { useFetch, useUrlParams } from "@/hooks/hooks";
import { useTranslation } from "react-i18next";
import { Loader } from "@components/general/loader/Loader";
import { CartItem } from "@/common/types/types";

import styles from "./styles.module.scss";
import { ProductCard2 } from "@/components/productCard/ProductCard2";
import { getProductImage } from "@/helpers/helpers";

export const GarageDetail = (): React.ReactElement => {
  const { t } = useTranslation();
  const { slug } = useParams() as { slug: string };
  const { state } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const { querySizes, queryMaxPrice, querySubcategories } =
    useUrlParams(searchParams);

  const decodedCategory = decodeURIComponent(slug);

  const queryParams = new URL(
    `${ROOT}${ApiPath.ITEMSAPI}&filters[category][$eq]=Jet Ski&pagination[page]=${page}&pagination[pageSize]=9`,
  );

  querySizes?.forEach((s) => {
    queryParams.searchParams.append("filters[size][$containsi]", s);
  });

  if (queryMaxPrice) {
    queryParams.searchParams.append("filters[price][$lte]", queryMaxPrice);
  }

  querySubcategories?.forEach((s) => {
    queryParams.searchParams.append(
      "filters[$and][0][subcategory][title][$eq]",
      s.charAt(0).toUpperCase() + s.slice(1),
    );
  });

  const { data, meta } = useFetch(queryParams.toString());

  useEffect(() => {
    setPageCount(meta?.pagination?.pageCount);
  }, [data]);

  useEffect(() => {
    setPage(1);
  }, [searchParams]);

  const handleRemoveSizeFilter = (s: string): void => {
    const params: {
      size?: string[];
      maxPrice?: string;
      subcat?: string[];
    } = {};
    if (querySizes) {
      params.size = querySizes.filter((p) => p !== s);
    }

    if (queryMaxPrice) {
      params.maxPrice = queryMaxPrice;
    }

    if (querySubcategories) {
      params.subcat = querySubcategories;
    }

    setSearchParams(params);
  };

  const handleRemovePriceFilter = (p: string): void => {
    const params: {
      size?: string[];
      subcat?: string[];
    } = {};
    if (querySizes) {
      params.size = querySizes;
    }

    if (querySubcategories) {
      params.subcat = querySubcategories;
    }

    setSearchParams(params);
  };

  const handleRemoveSubcategoryFilter = (s: string): void => {
    const params: {
      size?: string[];
      maxPrice?: string;
      subcat?: string[];
    } = {};
    if (querySizes) {
      params.size = querySizes;
    }

    if (queryMaxPrice) {
      params.maxPrice = queryMaxPrice;
    }

    if (querySubcategories) {
      params.subcat = querySubcategories.filter((p) => p !== s);
    }

    setSearchParams(params);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ): void => {
    setPage(value);
  };

  return (
    <Box className={styles.pageContent}>
      <Container>
        <Grid container spacing={4}>
          <Grid item md={12} sm={12}>
            {/* <div className="text-white text-2xl font-medium whitespace-nowrap bg-sky-900 justify-center pl-5 pr-16 py-4 rounded-xl items-start max-md:max-w-full max-md:pr-5">
              Garage List
            </div> */}

            <div className="items-center flex flex-col justify-center px-2 py-12 max-md:px-5">
              <div className="flex w-full max-w-[1140px] flex-col items-stretch my-5 max-md:max-w-full">
                <div className="text-white text-center text-2xl font-medium whitespace-nowrap bg-sky-900 justify-center pl-5 pr-16 py-4 rounded-xl items-start max-md:max-w-full max-md:pr-5">
                  Garage
                </div>
                <div className="bg-sky-50 mt-5 p-8 rounded-xl max-md:max-w-full max-md:px-5">
                  <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                    <div className="flex flex-col items-stretch w-[24%] max-md:w-full max-md:ml-0">
                      <div className="justify-center items-center self-stretch bg-sky-700 flex grow flex-col w-full p-12 max-md:mt-10 max-md:px-5">
                        <img
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/a3f35645256c11d816e588c995f90ac253e80fd2945cf40b310a7c390433d5c9?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                          className="aspect-square object-contain object-center w-[150px] overflow-hidden my-3"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col items-stretch w-[51%] ml-5 max-md:w-full max-md:ml-0">
                      <div className="items-stretch flex grow flex-col px-8 py-10 max-md:max-w-full max-md:mt-10 max-md:px-5">
                        <div className="text-black text-2xl font-semibold max-md:max-w-full">
                          Garage Name
                        </div>
                        <div className="text-black text-base mr-3 mt-2.5 max-md:max-w-full max-md:mr-2.5">
                          Desccription about garage and services done by garage
                        </div>
                        <div className="items-stretch flex gap-2.5 mt-2.5 py-5 self-start max-md:justify-center">
                          <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/3ef029b4d531287e21cff3b07d2c1694cb573116dc295e799f4a3eb4f3b4244d?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                            className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
                          />
                          <div className="text-black text-base">
                            Joined Date :{" "}
                          </div>
                          <div className="text-black text-base font-semibold grow whitespace-nowrap">
                            10-10-2020
                          </div>
                        </div>
                        <div className="items-stretch border bg-white flex w-[88px] max-w-full gap-5 mt-2.5 p-2.5 rounded-md border-solid border-gray-200 self-start">
                          <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/1144cc1224942a6514b95cade28618071f001e8940bdb1bb1ad3ebfd4cbe907d?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                            className="aspect-square object-contain object-center w-full overflow-hidden shrink-0 flex-1"
                          />
                          <div className="text-black text-base font-semibold">
                            4.0
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col items-stretch w-3/12 ml-5 max-md:w-full max-md:ml-0">
                      <div className="items-stretch self-stretch flex grow flex-col pl-12 pt-6 pb-12 max-md:mt-10 max-md:pl-5">
                        <div className="items-stretch bg-sky-500 flex justify-between gap-5 pl-6 pr-20 py-2 rounded-[30px] max-md:px-5   cursor-pointer transition-all duration-300 ease-in-out hover:bg-yellow-500">
                          <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/e5e8b326ddd9472b4a942a2eee7a09668f4036f618ec77dde714e338b5ad5cae?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                            className="aspect-square object-contain object-center w-[30px] overflow-hidden shrink-0 max-w-full"
                          />
                          <div className="text-white text-xl font-semibold self-start ">
                            Share
                          </div>
                        </div>
                        <div className="items-stretch bg-amber-400 flex justify-between gap-5 mt-5 mb-16 pl-6 pr-14 py-2 rounded-[30px] max-md:mb-10 max-md:px-5   cursor-pointer transition-all duration-300 ease-in-out hover:bg-yellow-500">
                          <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/ef174de3b6310a2796eea45181ba79035d3b817b5a102e5e762088aa8664599a?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                            className="aspect-square object-contain object-center w-[30px] overflow-hidden shrink-0 max-w-full"
                          />
                          <div className="text-white text-xl font-semibold self-start">
                            Location
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 pt-8 pb-2.5 px-2.5 max-md:max-w-full">
                  <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                    <div className="flex flex-col items-stretch w-[34%] max-md:w-full max-md:ml-0">
                      <div className="items-stretch self-stretch flex grow flex-col pb-12 max-md:mt-10">
                        <div className="text-black text-2xl font-semibold whitespace-nowrap">
                          Review this garage
                        </div>
                        <div className="text-zinc-500 text-base whitespace-nowrap mt-1.5">
                          Share your thoughts with other customers
                        </div>
                        <div className="text-white text-center font-semibold bg-sky-500 justify-center mt-1.5 mb-40 px-8 py-2.5 rounded-[35px] max-md:mb-10 max-md:px-5   cursor-pointer transition-all duration-300 ease-in-out hover:bg-yellow-500">
                          Write a Customer review
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col items-stretch w-[66%] ml-5 max-md:w-full max-md:ml-0">
                      <div className="items-stretch flex grow flex-col pl-10 py-5 border-l-neutral-200 border-l border-solid max-md:max-w-full max-md:mt-10 max-md:pl-5">
                        <div className="border flex justify-between gap-2.5 p-2.5 rounded-xl border-solid border-zinc-100 items-start max-md:max-w-full max-md:flex-wrap">
                          <div className="justify-center items-center border flex aspect-square flex-col w-10 h-10 px-1 rounded-3xl border-solid border-stone-300">
                            <img
                              loading="lazy"
                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/955c60e54bd61be26c70762062a35532d7af23d8ef18d8de88301667442a47bf?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                              className="aspect-square object-contain object-center w-full overflow-hidden"
                            />
                          </div>
                          <div className="items-stretch self-stretch flex grow basis-[0%] flex-col max-md:max-w-full">
                            <div className="text-black text-sm font-semibold max-md:max-w-full">
                              Username | Comment Heading
                            </div>
                            <div className="text-black text-sm font-light max-md:max-w-full">
                              Customer review description
                            </div>
                            <div className="items-stretch flex gap-1.5 pr-20 py-2.5 max-md:max-w-full max-md:flex-wrap max-md:pr-5">
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/c4e8c6bc81fb4b8e72b22458e468abf49fce4a329908d6ecaef7d2cf49fa3127?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                                className="aspect-square object-contain object-center w-[18px] overflow-hidden shrink-0 max-w-full"
                              />
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/cdfa710858ed64a0eb4ecfea09d4cb389cd6ae6434a97a533849d1594089bdeb?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                                className="aspect-square object-contain object-center w-[18px] overflow-hidden shrink-0 max-w-full"
                              />
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/cdfa710858ed64a0eb4ecfea09d4cb389cd6ae6434a97a533849d1594089bdeb?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                                className="aspect-square object-contain object-center w-[18px] overflow-hidden shrink-0 max-w-full"
                              />
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/cdfa710858ed64a0eb4ecfea09d4cb389cd6ae6434a97a533849d1594089bdeb?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                                className="aspect-square object-contain object-center w-[18px] overflow-hidden shrink-0 max-w-full"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="border flex justify-between gap-2.5 mt-2.5 p-2.5 rounded-xl border-solid border-zinc-100 items-start max-md:max-w-full max-md:flex-wrap">
                          <div className="justify-center items-center border flex aspect-square flex-col w-10 h-10 px-1 rounded-3xl border-solid border-stone-300">
                            <img
                              loading="lazy"
                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/955c60e54bd61be26c70762062a35532d7af23d8ef18d8de88301667442a47bf?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                              className="aspect-square object-contain object-center w-full overflow-hidden"
                            />
                          </div>
                          <div className="items-stretch self-stretch flex grow basis-[0%] flex-col max-md:max-w-full">
                            <div className="text-black text-sm font-semibold max-md:max-w-full">
                              Username | Comment Heading
                            </div>
                            <div className="text-black text-sm font-light max-md:max-w-full">
                              Customer review description
                            </div>
                            <div className="items-stretch flex gap-1.5 pr-20 py-2.5 max-md:max-w-full max-md:flex-wrap max-md:pr-5">
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/c4e8c6bc81fb4b8e72b22458e468abf49fce4a329908d6ecaef7d2cf49fa3127?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                                className="aspect-square object-contain object-center w-[18px] overflow-hidden shrink-0 max-w-full"
                              />
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/cdfa710858ed64a0eb4ecfea09d4cb389cd6ae6434a97a533849d1594089bdeb?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                                className="aspect-square object-contain object-center w-[18px] overflow-hidden shrink-0 max-w-full"
                              />
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/cdfa710858ed64a0eb4ecfea09d4cb389cd6ae6434a97a533849d1594089bdeb?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                                className="aspect-square object-contain object-center w-[18px] overflow-hidden shrink-0 max-w-full"
                              />
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/cdfa710858ed64a0eb4ecfea09d4cb389cd6ae6434a97a533849d1594089bdeb?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                                className="aspect-square object-contain object-center w-[18px] overflow-hidden shrink-0 max-w-full"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="border flex justify-between gap-2.5 mt-2.5 p-2.5 rounded-xl border-solid border-zinc-100 items-start max-md:max-w-full max-md:flex-wrap">
                          <div className="justify-center items-center border flex aspect-square flex-col w-10 h-10 px-1 rounded-3xl border-solid border-stone-300">
                            <img
                              loading="lazy"
                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/955c60e54bd61be26c70762062a35532d7af23d8ef18d8de88301667442a47bf?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                              className="aspect-square object-contain object-center w-full overflow-hidden"
                            />
                          </div>
                          <div className="items-stretch self-stretch flex grow basis-[0%] flex-col max-md:max-w-full">
                            <div className="text-black text-sm font-semibold max-md:max-w-full">
                              Username | Comment Heading
                            </div>
                            <div className="text-black text-sm font-light max-md:max-w-full">
                              Customer review description
                            </div>
                            <div className="items-stretch flex gap-1.5 pr-20 py-2.5 max-md:max-w-full max-md:flex-wrap max-md:pr-5">
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/c4e8c6bc81fb4b8e72b22458e468abf49fce4a329908d6ecaef7d2cf49fa3127?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                                className="aspect-square object-contain object-center w-[18px] overflow-hidden shrink-0 max-w-full"
                              />
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/cdfa710858ed64a0eb4ecfea09d4cb389cd6ae6434a97a533849d1594089bdeb?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                                className="aspect-square object-contain object-center w-[18px] overflow-hidden shrink-0 max-w-full"
                              />
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/cdfa710858ed64a0eb4ecfea09d4cb389cd6ae6434a97a533849d1594089bdeb?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                                className="aspect-square object-contain object-center w-[18px] overflow-hidden shrink-0 max-w-full"
                              />
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/cdfa710858ed64a0eb4ecfea09d4cb389cd6ae6434a97a533849d1594089bdeb?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                                className="aspect-square object-contain object-center w-[18px] overflow-hidden shrink-0 max-w-full"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="border flex justify-between gap-2.5 mt-2.5 p-2.5 rounded-xl border-solid border-zinc-100 items-start max-md:max-w-full max-md:flex-wrap">
                          <div className="justify-center items-center border flex aspect-square flex-col w-10 h-10 px-1 rounded-3xl border-solid border-stone-300">
                            <img
                              loading="lazy"
                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/955c60e54bd61be26c70762062a35532d7af23d8ef18d8de88301667442a47bf?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                              className="aspect-square object-contain object-center w-full overflow-hidden"
                            />
                          </div>
                          <div className="items-stretch self-stretch flex grow basis-[0%] flex-col max-md:max-w-full">
                            <div className="text-black text-sm font-semibold max-md:max-w-full">
                              Username | Comment Heading
                            </div>
                            <div className="text-black text-sm font-light max-md:max-w-full">
                              Customer review description
                            </div>
                            <div className="items-stretch flex gap-1.5 pr-20 py-2.5 max-md:max-w-full max-md:flex-wrap max-md:pr-5">
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/c4e8c6bc81fb4b8e72b22458e468abf49fce4a329908d6ecaef7d2cf49fa3127?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                                className="aspect-square object-contain object-center w-[18px] overflow-hidden shrink-0 max-w-full"
                              />
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/cdfa710858ed64a0eb4ecfea09d4cb389cd6ae6434a97a533849d1594089bdeb?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                                className="aspect-square object-contain object-center w-[18px] overflow-hidden shrink-0 max-w-full"
                              />
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/cdfa710858ed64a0eb4ecfea09d4cb389cd6ae6434a97a533849d1594089bdeb?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                                className="aspect-square object-contain object-center w-[18px] overflow-hidden shrink-0 max-w-full"
                              />
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/cdfa710858ed64a0eb4ecfea09d4cb389cd6ae6434a97a533849d1594089bdeb?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                                className="aspect-square object-contain object-center w-[18px] overflow-hidden shrink-0 max-w-full"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {pageCount > 1 && (
              <Grid container mt={4} className={styles.paginationContainer}>
                <Pagination
                  count={pageCount}
                  page={page}
                  onChange={handlePageChange}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
