import React from "react";
import { Box, Grid, Typography, Skeleton } from "@mui/material";
import { ApiPath, ROOT } from "@enums/apiPath";
import { getProductImage } from "@helpers/helpers";
import { useFetch } from "@/hooks/hooks";
import { Category } from "@/common/types/types";
import { Image } from "@components/general/image/Image";
import { Link, useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";

export const ActivityBlocks = (): React.ReactElement => {
  const navigate = useNavigate();
  const { data, loading, error } = useFetch(
    `${ROOT}${ApiPath.CATEGORIESAPI}?populate=image`,
  );

  const handleEvents = (): void => {
    navigate("/events");
  };

  const handleCommunity = (): void => {
    navigate("/communities");
  };

  const handleGarage = (): void => {
    navigate("/garagelist");
  };

  return (
    <Box className={styles.section}>
      <div className="bg-white py-11 max-md:max-w-full">
        <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
          <div className="flex flex-col items-stretch w-[33%] max-md:w-full max-md:ml-0">
            <div
              onClick={handleEvents}
              className="items-center bg-sky-900 flex grow flex-col w-full mt-2.5 px-2.5 py-12 max-md:mt-10   cursor-pointer transition-all duration-300 ease-in-out hover:bg-yellow-500"
            >
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/29bdf1349888bd4e5cb8cef010488ada01ad28ac5573158ec3fe27645ef2dd13?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                className="aspect-square object-contain object-center w-20 overflow-hidden max-w-full   transition-transform duration-300 transform hover:scale-110"
              />
              <div className="self-stretch text-white text-center text-2xl font-semibold whitespace-nowrap mt-1.5">
                Events
              </div>
            </div>
          </div>
          <div className="flex flex-col items-stretch w-[33%] ml-5 max-md:w-full max-md:ml-0">
            <div
              onClick={handleCommunity}
              className="items-center bg-sky-900 flex grow flex-col w-full mt-2.5 px-2.5 py-12 max-md:mt-10   cursor-pointer transition-all duration-300 ease-in-out hover:bg-yellow-500"
            >
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/ff2bba961845e62aba1ac7c2b66526cbdcaad403b711c9c1f5144545df66b62e?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                className="aspect-square object-contain object-center w-20 overflow-hidden max-w-full   transition-transform duration-300 transform hover:scale-110"
              />
              <div className="self-stretch text-white text-center text-2xl font-semibold whitespace-nowrap mt-1.5">
                Community
              </div>
            </div>
          </div>
          <div
            onClick={handleGarage}
            className="flex flex-col items-stretch w-[33%] ml-5 max-md:w-full max-md:ml-0"
          >
            <div className="items-center bg-sky-900 flex grow flex-col w-full mt-2.5 px-2.5 py-12 max-md:mt-10   cursor-pointer transition-all duration-300 ease-in-out hover:bg-yellow-500">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/2399d58633d15387c9cbed104e36fc6a965885b7180c1c0f2c9ca70821979508?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                className="aspect-square object-contain object-center w-20 overflow-hidden max-w-full   transition-transform duration-300 transform hover:scale-110"
              />
              <div className="self-stretch text-white text-center text-2xl font-semibold whitespace-nowrap mt-1.5">
                Garage
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};
