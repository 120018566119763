import React from "react";
import {
  Box,
  Grid,
  IconButton,
  Button,
  Skeleton,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import clsx from "clsx";
import { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { CartItem } from "@/common/types/types";
import { ProductCard } from "@components/productCard/ProductCard";
import { ProductsSliderSkeleton } from "./ProductsSliderSkeleton";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

interface CategorySliderProps {
  title?: string;
  category?: string;
  slides: CartItem[];
  loading?: boolean;
}

export const ProductsSlider = ({
  title,
  category,
  slides,
  loading,
}: CategorySliderProps): React.ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleViewmore = (): void => {
    // navigate("/category/men");
    navigate(`/category/${category !== undefined ? category : ""}`, {
      state: { title: category },
    });
  };

  return (
    <>
      <Box className={styles.section}>
        <Grid
          container
          spacing={2}
          sx={{ marginTop: "1px", marginBottom: "5px" }}
        >
          <Grid xs={8}>
            {title && (
              <Typography variant="h6" sx={{ fontWeight: 600, marginLeft: 2 }}>
                {title}
              </Typography>
            )}
          </Grid>
          <Grid
            xs={4}
            sx={{
              alignItems: "flex-end",
              justifyContent: "flex-end",
              display: "flex",
            }}
          >
            <Button
              sx={{
                alignSelf: "flex-end", // Added this prop to right-align the text
                fontWeight: 200,
                textAlign: "center",
                backgroundColor: "#424242",
                color: "#fff",
                width: "110px",
                borderRadius: 30,
                paddingRight: 15,
                paddingLeft: 15,
                padding: "5px",
                fontSize: "12px",
                "&:hover": {
                  backgroundColor: "#D3A56B",
                },
              }}
              onClick={handleViewmore}
            >
              {t("viewall")}
            </Button>
          </Grid>
        </Grid>

        {loading ? (
          <ProductsSliderSkeleton />
        ) : (
          <Swiper
            modules={[Navigation, Autoplay]}
            spaceBetween={0}
            slidesPerView={1}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
              disabledClass: "swiper-button-disabled",
            }}
            loop={false}
            breakpoints={{
              600: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              900: {
                slidesPerView: 4,
                spaceBetween: 15,
              },
            }}
            className={styles.carousel}
          >
            {slides?.map((slide, index) => (
              <SwiperSlide key={index}>
                <ProductCard item={slide} />
              </SwiperSlide>
            ))}
          </Swiper>
        )}

        {/* <Container
          sx={{ position: "relative", height: "100%" }}
          className={styles.arrowsContainer}
        >
          <IconButton className={clsx("swiper-button-prev", styles.arrowPrev)}>
            <ArrowBackIosIcon />
          </IconButton>
          <IconButton className={clsx("swiper-button-next", styles.arrowNext)}>
            <ArrowForwardIosIcon />
          </IconButton>
        </Container> */}
      </Box>
    </>
  );
};
