import React from "react";
import { CategoriesBlocks } from "@/components/categoriesBlocks/CategoriesBlocks";
import { FullWidthCarousel } from "@/components/fullWidthCarousel/FullWidthCarousel";
import { NewArrivalsSlider } from "@/components/newArrivalsSlider/NewArrivalsSlider";
import { FeaturedListSlider } from "@/components/featuredListSlider/FeaturedListSlider";
import { BestSellersSlider } from "@/components/bestSellersSlider/BestSellersSlider";
import { Subscribe } from "@/components/subscribe/Subscribe";
import { Notification } from "@/components/notification/Notification";
import { ProductsListSlider } from "@/components/productsListSlider/productsListSlider";
import { AccessoriesListSlider } from "@/components/accessoriesListSlider/AccessoriesListSlider";
import { ApparelListSlider } from "@/components/apparelListSlider/ApparelListSlider";
import { Sellbox } from "@/components/sellbox/Sellbox";
import { FeaturedCatbox } from "@/components/featuredCatbox/FeaturedCatbox";
import { FeaturedProductbox } from "@/components/featuredProductbox/FeaturedProductbox";
import { GeneralProductbox } from "@/components/generalProductbox/GeneralProductbox";
import { GeneralAccessoriesbox } from "@/components/generalAccessoriesbox/GeneralAccessoriesbox";
import { ApparelProductbox } from "@/components/apparelProductbox/ApparelProductbox";
import { ActivityBlocks } from "@/components/activityBlocks/ActivityBlocks";

export const Home = (): React.ReactElement => {
  return (
    <>
      {/* <Notification /> */}
      <FullWidthCarousel />
      <Sellbox />
      <FeaturedCatbox />
      <FeaturedProductbox />
      <GeneralProductbox />
      <GeneralAccessoriesbox />
      <ApparelProductbox />
      <ActivityBlocks />
      {/* <FeaturedListSlider /> */}
      {/* <ProductsListSlider /> */}
      {/* <AccessoriesListSlider /> */}
      {/* <ApparelListSlider /> */}
      {/* <NewArrivalsSlider /> */}
      {/* <CategoriesBlocks /> */}
      {/* <BestSellersSlider /> */}

      {/* <Subscribe /> */}
    </>
  );
};
