import React, { useState, useEffect } from "react";
import { addToCartById } from "@store/cart/slice";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { CartItem } from "@/common/types/types";
import { Box, Button, Typography } from "@mui/material";
import {
  formatPrice,
  formatStringCapitalize,
  getProductImage,
} from "@helpers/helpers";
import { Sizes } from "../general/sizes/Sizes";
import { useTranslation } from "react-i18next";
import { Image } from "@components/general/image/Image";

import styles from "./styles.module.scss";

interface ProductCardProps {
  item: CartItem;
}

export const ProductCard2 = ({
  item,
}: ProductCardProps): React.ReactElement => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const cart = useAppSelector((state) => state.cart.cart);
  const [choseSize, setChoseSize] = useState<string>("");
  const [error, setError] = useState<string>();
  const { t } = useTranslation();

  const { price, name, category, size, slug } = item.attributes;
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const productLink = `/item/${slug}`;

  const handleAddCart = (): void => {
    // navigate(productLink);
    if (size) {
      if (!choseSize) {
        setError(t("chooseSizeError"));
        return;
      } else {
        setError("");
      }
    }
    dispatch(
      addToCartById({
        id: item.id,
        count: 1,
        size: choseSize,
        price: item.attributes.price,
        sku: item.attributes.sku,
      }),
    );
  };

  const handleSizeClick = (s: string): void => {
    setError("");
    setChoseSize(s);
  };

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cart));
  }, [cart]);

  return (
    <div
      key={item?.id}
      onClick={() => navigate(`/item/${item?.attributes?.slug}`)}
      className="flex flex-col items-stretch max-md:w-full max-md:ml-0"
    >
      <div className="justify-center items-center shadow-sm bg-white flex grow basis-[0%] flex-col pt-2.5 pb-5 px-2.5">
        <img
          loading="lazy"
          src={getProductImage(item?.attributes?.image)}
          alt=""
          // srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/888c0ce26d71e61bfd204ad4f2a5a36fcc5e5496a7226fabc5aa230090158f1e?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/888c0ce26d71e61bfd204ad4f2a5a36fcc5e5496a7226fabc5aa230090158f1e?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/888c0ce26d71e61bfd204ad4f2a5a36fcc5e5496a7226fabc5aa230090158f1e?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/888c0ce26d71e61bfd204ad4f2a5a36fcc5e5496a7226fabc5aa230090158f1e?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/888c0ce26d71e61bfd204ad4f2a5a36fcc5e5496a7226fabc5aa230090158f1e?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/888c0ce26d71e61bfd204ad4f2a5a36fcc5e5496a7226fabc5aa230090158f1e?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/888c0ce26d71e61bfd204ad4f2a5a36fcc5e5496a7226fabc5aa230090158f1e?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/888c0ce26d71e61bfd204ad4f2a5a36fcc5e5496a7226fabc5aa230090158f1e?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
          className="aspect-[1.3] object-contain object-center w-48 overflow-hidden   transition-transform duration-300 transform hover:scale-110"
        />
        <div className="self-stretch text-black text-base leading-5 mt-2 text-left">
          {item?.attributes?.name}
        </div>
        <div className="text-black text-sm font-bold self-stretch whitespace-nowrap mt-5 text-left">
          AED 347.00
        </div>
      </div>
    </div>
  );
};
