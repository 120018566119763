import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import { useFormik } from "formik";
import {
  subscribeInitialValues,
  subscribeSchema,
} from "@/common/validationSchemas/schemas";
import { SubscribeSchemaValues } from "@/common/types/types";
import { ApiPath, ROOT } from "@/common/enums/apiPath";

export const Sellbox = (): React.ReactElement => {
  const [message, setMessage] = useState({ text: "", status: "" });
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleViewmore = (): void => {
    navigate("/addpost");
  };

  const handleSubscribe = async (
    values: SubscribeSchemaValues,
  ): Promise<void> => {
    setMessage({ text: "", status: "" });

    const requestBody = {
      email: values.email,
    };

    const response = await fetch(`${ROOT}${ApiPath.SUBSCRIBERAPI}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody),
    });

    const { error, data } = await response.json();

    if (data) {
      setMessage({ text: t(data.message), status: "" });
    }

    if (error) setMessage({ text: t(error.message), status: "error" });
  };

  const formik = useFormik({
    initialValues: subscribeInitialValues,
    validationSchema: subscribeSchema,
    onSubmit: (values, { resetForm }) => {
      void handleSubscribe(values);
      resetForm();
    },
  });

  return (
    <Box className={styles.section}>
      <div className="justify-between items-stretch bg-sky-950 flex gap-5 px-10 py-8 rounded-xl max-md:max-w-full max-md:flex-wrap max-md:px-5">
        <div className="text-white text-2xl font-medium shrink basis-auto max-md:max-w-full">
          {t("selljetski")}
        </div>
        <div
          onClick={handleViewmore}
          className="text-white text-base font-semibold whitespace-nowrap justify-center items-stretch px-9 py-1.5 rounded-[30px] border-[3px] border-solid border-sky-500 max-md:px-5 cursor-pointer transition-all duration-300 ease-in-out hover:bg-yellow-500"
        >
          {t("paypost")}
        </div>
      </div>
    </Box>
  );
};
