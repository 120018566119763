import { Outlet, ScrollRestoration } from "react-router-dom";
import { Footer } from "@components/footer/Footer";
import { Header } from "@components/header/Header";
import { MiniHeader } from "../header2/miniHeader";
import { MiniCart } from "../miniCart/miniCart";

export const Layout = (): React.ReactElement => {
  return (
    <>
      <Header />
      {/* <MiniHeader /> */}
      <MiniCart />
      <main>
        <Outlet />
      </main>
      <Footer />
      <ScrollRestoration />
    </>
  );
};
