import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BannerState, BannerSlide } from "@/common/types/types";
import { ApiPath, ROOT } from "@/common/enums/apiPath";
import { RootState } from "@store/store";
import { getBannerImage, getProductImage } from "@/helpers/helpers";

const initialState: BannerState = {
  data: null,
};

export const fetchBannerSlides = createAsyncThunk(
  `${ROOT}${ApiPath.BANNERAPI}`,
  async (_, { getState }) => {
    const { settings } = getState() as RootState;
    const endpoint = `${ROOT}${ApiPath.BANNERAPI}?populate=image`;
    // const endpoint = `${ROOT}${ApiPath.BANNERAPI}?populate=image&locale=${settings.language}`;
    const response = await fetch(endpoint);

    const slides = await response.json();
    // slide.attributes.image.data.attributes.url
    const data = [...(await slides).data].map((slide) => {
      const item: BannerSlide = {
        image: getBannerImage(slide),
        title: slide?.attributes?.title,
        link: slide?.attributes?.link,
      };
      return item;
    });

    return data;
  },
);

export const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBannerSlides.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});

export default bannerSlice.reducer;
