import {
  Box,
  Container,
  Typography,
  Grid,
  Chip,
  Pagination,
} from "@mui/material";
import {
  useParams,
  useLocation,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import { ApiPath, ROOT } from "@enums/apiPath";
import { ProductCard } from "@components/productCard/ProductCard";
import { Filters } from "@/components/filters/Filters";
import { useFetch, useUrlParams } from "@/hooks/hooks";
import { useTranslation } from "react-i18next";
import { Loader } from "@components/general/loader/Loader";
import { CartItem } from "@/common/types/types";

import styles from "./styles.module.scss";
import { ProductCard2 } from "@/components/productCard/ProductCard2";
import { getProductImage } from "@/helpers/helpers";
import { GarageItem } from "@/common/types/garage/garage";

export const Garagelist = (): React.ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { slug } = useParams() as { slug: string };
  const { state } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const { querySizes, queryMaxPrice, querySubcategories } =
    useUrlParams(searchParams);

  const decodedCategory = decodeURIComponent(slug);

  const queryParams = new URL(
    `${ROOT}${ApiPath.ITEMSAPI}&filters[category][$eq]=Jet Ski&pagination[page]=${page}&pagination[pageSize]=9`,
  );

  querySizes?.forEach((s) => {
    queryParams.searchParams.append("filters[size][$containsi]", s);
  });

  if (queryMaxPrice) {
    queryParams.searchParams.append("filters[price][$lte]", queryMaxPrice);
  }

  querySubcategories?.forEach((s) => {
    queryParams.searchParams.append(
      "filters[$and][0][subcategory][title][$eq]",
      s.charAt(0).toUpperCase() + s.slice(1),
    );
  });

  const { data, meta } = useFetch(queryParams.toString());

  useEffect(() => {
    setPageCount(meta?.pagination?.pageCount);
  }, [data]);

  useEffect(() => {
    setPage(1);
  }, [searchParams]);

  const handleRemoveSizeFilter = (s: string): void => {
    const params: {
      size?: string[];
      maxPrice?: string;
      subcat?: string[];
    } = {};
    if (querySizes) {
      params.size = querySizes.filter((p) => p !== s);
    }

    if (queryMaxPrice) {
      params.maxPrice = queryMaxPrice;
    }

    if (querySubcategories) {
      params.subcat = querySubcategories;
    }

    setSearchParams(params);
  };

  const handleRemovePriceFilter = (p: string): void => {
    const params: {
      size?: string[];
      subcat?: string[];
    } = {};
    if (querySizes) {
      params.size = querySizes;
    }

    if (querySubcategories) {
      params.subcat = querySubcategories;
    }

    setSearchParams(params);
  };

  const handleRemoveSubcategoryFilter = (s: string): void => {
    const params: {
      size?: string[];
      maxPrice?: string;
      subcat?: string[];
    } = {};
    if (querySizes) {
      params.size = querySizes;
    }

    if (queryMaxPrice) {
      params.maxPrice = queryMaxPrice;
    }

    if (querySubcategories) {
      params.subcat = querySubcategories.filter((p) => p !== s);
    }

    setSearchParams(params);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ): void => {
    setPage(value);
  };

  const handleViewmore = (item: CartItem): void => {
    navigate(ApiPath.GARAGEDETAIL, { state: { item } });
  };

  return (
    <Box className={styles.pageContent}>
      <Container>
        <Grid container spacing={4}>
          <Grid item md={12} sm={12}>
            <div className="text-white text-2xl font-medium whitespace-nowrap bg-sky-900 justify-center pl-5 pr-16 py-4 rounded-xl items-start max-md:max-w-full max-md:pr-5">
              Garage List
            </div>

            <div className="items-stretch bg-amber-50 flex flex-col mt-2.5 px-8 py-12 rounded-xl max-md:max-w-full max-md:px-5">
              <Grid container spacing={2} mt={2}>
                {data ? (
                  data.map((item: CartItem) => (
                    <div
                      key={item.id}
                      onClick={() => {
                        handleViewmore(item);
                      }}
                      className="bg-white p-4 mb-5 rounded-xl md:flex md:gap-2.5 md:pl-10 md:pr-14 md:max-w-full md:flex-wrap md:px-5"
                    >
                      <div className="bg-sky-700 self-center flex-shrink-0 w-auto h-40 rounded-xl overflow-hidden">
                        <img
                          loading="lazy"
                          src={getProductImage(item.attributes.image)}
                          alt=""
                          className="aspect-square w-full h-full   transition-transform duration-300 transform hover:scale-110"
                        />
                      </div>
                      <div className="flex-grow md md:w-2/5 pl-2 md:pl-9 pr-2 md:pr-20 py-2 md:py-5">
                        <div className="text-black text-xl font-semibold whitespace-nowrap">
                          Garage Name 01
                        </div>
                        <div className="text-black text-sm">
                          Description about the garage and services done by the
                          garage
                        </div>
                        <div className="flex gap-2.5 pr-2 md:pr-20 pt-2 md:pt-6 pb-2.5">
                          <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/3ef029b4d531287e21cff3b07d2c1694cb573116dc295e799f4a3eb4f3b4244d?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                            className="w-4 h-4 md:w-6 md:h-6"
                          />
                          <div className="text-black text-sm">
                            Joined Date:{" "}
                          </div>
                          <div className="text-black text-sm font-semibold whitespace-nowrap">
                            10-10-2020
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col items-end mt-2 gap-2 md:mt-0 pl-2 md:pl-14 pr-2.5 justify-between">
                        <div className="border flex w-20 md:w-20 gap-2 p-2 rounded-md border-solid border-gray-200">
                          <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/1144cc1224942a6514b95cade28618071f001e8940bdb1bb1ad3ebfd4cbe907d?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                            className="w-5 h-5"
                          />
                          <div className="text-black text-base font-semibold">
                            4.0
                          </div>
                        </div>
                        <div className="text-white text-l font-semibold mt-2 bg-sky-500 px-4 py-2.5 rounded  cursor-pointer transition-all duration-300 ease-in-out hover:bg-yellow-500">
                          View More
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <Loader />
                )}
                {data?.length === 0 ? (
                  <Typography className={styles.notFound}>
                    Nothing found.
                  </Typography>
                ) : null}
              </Grid>
            </div>

            {pageCount > 1 && (
              <Grid container mt={4} className={styles.paginationContainer}>
                <Pagination
                  count={pageCount}
                  page={page}
                  onChange={handlePageChange}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
