import React, { useState, useEffect } from "react";
import { addToCartById } from "@store/cart/slice";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { CartItem } from "@/common/types/types";
import { Box, Button, Typography } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { LazyLoadImage } from "react-lazy-load-image-component";

import {
  formatPrice,
  formatStringCapitalize,
  getProductImage,
} from "@helpers/helpers";
import { Sizes } from "../general/sizes/Sizes";
import { useTranslation } from "react-i18next";
import { Image } from "@components/general/image/Image";

import styles from "./styles.module.scss";

interface ProductCardProps {
  item: CartItem;
}

export const ProductCard = ({ item }: ProductCardProps): React.ReactElement => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const cart = useAppSelector((state) => state.cart.cart);
  const [choseSize, setChoseSize] = useState<string>("");
  const [error, setError] = useState<string>();
  const [isFavorite, setIsFavorite] = useState(false);
  const { t } = useTranslation();

  const { price, name, category, type, size, slug } = item.attributes;
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const productLink = `/item/${slug}`;

  const handleFavoriteToggle = (): void => {
    // Toggle the favorite state
    setIsFavorite(!isFavorite);
    // Implement logic to add/remove item from favorites
    // You can use a global state, local storage, or an API call
  };

  const handleViewmore = (): void => {
    navigate(productLink);
    // if (size) {
    //   if (!choseSize) {
    //     setError(t("chooseSizeError"));
    //     return;
    //   } else {
    //     setError("");
    //   }
    // }
    // dispatch(
    //   addToCartById({
    //     id: item.id,
    //     count: 1,
    //     size: choseSize,
    //     price: item.attributes.price,
    //     sku: item.attributes.sku,
    //   }),
    // );
  };

  const handleSizeClick = (s: string): void => {
    setError("");
    setChoseSize(s);
  };

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cart));
  }, [cart]);

  return (
    <div
      key={item?.id}
      className="flex flex-col items-stretch max-w-full ml-0 bg-white shadow-lg mb-8"
    >
      <div className="justify-center items-stretch flex w-full grow flex-col mx-auto pt-2.5 pb-5 px-2.5 mt-2.5">
        <div className="flex justify-between">
          {item.attributes.type === "featured" && (
            <div className="mt-2 bg-featured-bg px-2 rounded-full flex items-center z-50">
              <span className="text-featured-text font-semibold">Featured</span>
            </div>
          )}
          <div>
            {isFavorite ? (
              <FavoriteIcon
                onClick={handleFavoriteToggle}
                className="cursor-pointer text-red-500"
              />
            ) : (
              <FavoriteBorderIcon
                onClick={handleFavoriteToggle}
                className="cursor-pointer"
              />
            )}
          </div>
        </div>

        <LazyLoadImage
          // loading="lazy"
          src={getProductImage(item.attributes.image)}
          alt=""
          onClick={() => navigate(`/item/${item?.attributes?.slug}`)}
          className="aspect-[1.81] object-contain object-center w-full overflow-hidden transition-transform duration-300 transform hover:scale-110"
        />

        <div
          onClick={() => navigate(`/item/${item?.attributes?.slug}`)}
          className="text-black text-base whitespace-nowrap mt-2 text-left"
        >
          {item.attributes.name}
        </div>

        <div
          onClick={() => navigate(`/item/${item?.attributes?.slug}`)}
          className="text-black text-sm font-bold whitespace-nowrap text-left"
        >
          AED {item.attributes.price}
        </div>

        <div
          onClick={() => navigate(`/item/${item?.attributes?.slug}`)}
          className="items-center flex gap-1.5 mt-4"
        >
          <LazyLoadImage
            // loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/189818d4136500ea5ed1babe213422c3cdb90fe6fe6b33bd7c19d0e418897a99?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
            className="aspect-square object-contain object-center w-5 overflow-hidden shrink-0 max-w-full my-auto"
          />
          <div className="text-black text-base text-left">4 Years</div>
        </div>

        <div
          onClick={() => navigate(`/item/${item?.attributes?.slug}`)}
          className="flex gap-1.5 py-3 items-start"
        >
          <LazyLoadImage
            // loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/55971f735487eb17bfe9a198fc311e96a6f36692cd570c0e98f59a03254953df?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
            className="aspect-square object-contain object-center w-5 overflow-hidden shrink-0 max-w-full"
          />
          <div className="text-black text-base text-left">Abu Dhabi</div>
        </div>

        <div
          onClick={() => navigate(`/item/${item?.attributes?.slug}`)}
          className="text-white text-base font-semibold whitespace-nowrap items-center border bg-sky-500 mt-1.5 py-1.5 rounded-[30px] border-solid border-sky-500 max-md:px-5 text-center cursor-pointer transition-all duration-300 ease-in-out hover:bg-yellow-500"
        >
          View more
        </div>
      </div>
    </div>

    // <Box>
    //   <div
    //     key={item?.id}
    //     className="flex flex-col items-stretch max-md:w-full max-md:ml-0 bg-white shadow-lg    mb-8"
    //   >
    //     <div className="justify-center items-stretch   flex w-full grow flex-col mx-auto pt-2.5 pb-5 px-2.5 max-md:mt-2.5">
    //       <Box
    //         display={"flex"}
    //         flexDirection={"row"}
    //         sx={{ justifyContent: "space-between" }}
    //       >
    //         <Box>
    //           {item.attributes.type === "featured" && (
    //             <div className="mt-2 bg-featured-bg px-2 rounded-full flex items-center">
    //               <span className="text-featured-text font-semibold">
    //                 Featured
    //               </span>
    //             </div>
    //             // <Box
    //             //   sx={{
    //             //     marginLeft: "8px",
    //             //     marginTop: "8px",
    //             //     backgroundColor: "#FCEDDA", // "#00539C",
    //             //     paddingRight: "8px",
    //             //     paddingLeft: "8px",
    //             //     borderRadius: "16px",
    //             //     alignItems: "center",
    //             //   }}
    //             // >
    //             //   <Typography
    //             //     color={"#17A6FF"}
    //             //     sx={{ fontWeight: "900px" }}
    //             //     variant="caption"
    //             //   >
    //             //     Featured
    //             //   </Typography>
    //             // </Box>
    //           )}
    //         </Box>

    //         <Box>
    //           {isFavorite ? (
    //             <FavoriteIcon
    //               onClick={handleFavoriteToggle}
    //               style={{
    //                 cursor: "pointer",
    //                 color: "red",
    //               }}
    //             />
    //           ) : (
    //             <FavoriteBorderIcon
    //               onClick={handleFavoriteToggle}
    //               style={{
    //                 cursor: "pointer",
    //               }}
    //             />
    //           )}
    //         </Box>
    //       </Box>

    //       <img
    //         loading="lazy"
    //         src={getProductImage(item.attributes.image)}
    //         alt=""
    //         onClick={() => navigate(`/item/${item?.attributes?.slug}`)}
    //         // srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/dfac4e603d2342b98772f71dfff4e138052ded07850f1702f6d44b299ccde1ad?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/dfac4e603d2342b98772f71dfff4e138052ded07850f1702f6d44b299ccde1ad?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/dfac4e603d2342b98772f71dfff4e138052ded07850f1702f6d44b299ccde1ad?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/dfac4e603d2342b98772f71dfff4e138052ded07850f1702f6d44b299ccde1ad?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/dfac4e603d2342b98772f71dfff4e138052ded07850f1702f6d44b299ccde1ad?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/dfac4e603d2342b98772f71dfff4e138052ded07850f1702f6d44b299ccde1ad?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/dfac4e603d2342b98772f71dfff4e138052ded07850f1702f6d44b299ccde1ad?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/dfac4e603d2342b98772f71dfff4e138052ded07850f1702f6d44b299ccde1ad?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
    //         className="aspect-[1.81] object-contain object-center w-full overflow-hidden  transition-transform duration-300 transform hover:scale-110"
    //       />
    //       <div
    //         onClick={() => navigate(`/item/${item?.attributes?.slug}`)}
    //         className="text-black text-base whitespace-nowrap mt-2 text-left"
    //       >
    //         {item.attributes.name}
    //       </div>
    //       <div
    //         onClick={() => navigate(`/item/${item?.attributes?.slug}`)}
    //         className="text-black text-sm font-bold whitespace-nowrap  text-left"
    //       >
    //         AED {item.attributes.price}
    //       </div>
    //       <div
    //         onClick={() => navigate(`/item/${item?.attributes?.slug}`)}
    //         className="items-center flex  gap-1.5 mt-4"
    //       >
    //         <img
    //           loading="lazy"
    //           src="https://cdn.builder.io/api/v1/image/assets/TEMP/189818d4136500ea5ed1babe213422c3cdb90fe6fe6b33bd7c19d0e418897a99?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
    //           className="aspect-square object-contain object-center w-5 overflow-hidden shrink-0 max-w-full my-auto"
    //         />
    //         <div className="text-black text-base  text-left">4 Years</div>
    //       </div>
    //       <div
    //         onClick={() => navigate(`/item/${item?.attributes?.slug}`)}
    //         className="flex  gap-1.5 py-3 items-start"
    //       >
    //         <img
    //           loading="lazy"
    //           src="https://cdn.builder.io/api/v1/image/assets/TEMP/55971f735487eb17bfe9a198fc311e96a6f36692cd570c0e98f59a03254953df?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
    //           className="aspect-square object-contain object-center w-5 overflow-hidden shrink-0 max-w-full"
    //         />
    //         <div className="text-black text-base  text-left">Abu Dhabi</div>
    //       </div>
    //       <div
    //         onClick={() => navigate(`/item/${item?.attributes?.slug}`)}
    //         className="text-white text-base font-semibold whitespace-nowrap items-center border bg-sky-500 mt-1.5 py-1.5 rounded-[30px] border-solid border-sky-500 max-md:px-5 text-center   cursor-pointer transition-all duration-300 ease-in-out hover:bg-yellow-500"
    //       >
    //         View more
    //       </div>
    //     </div>
    //   </div>
    // </Box>
  );
};
