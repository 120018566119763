import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Container,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Paper,
  Grid,
  SvgIcon,
  Alert,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import Input from "@mui/material/Input";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { CloseRounded } from "@mui/icons-material";
import { ReactComponent as SVG_Jetski } from "./../../assets/svg/jetski.svg";
import { ReactComponent as SVG_Camera } from "./../../assets/svg/camera.svg";
import { ReactComponent as SVG_Jetskiblue } from "./../../assets/svg/jetskiblue.svg";
import { ReactComponent as SVG_Accessories } from "./../../assets/svg/accessories.svg";
import { ReactComponent as SVG_Accessoriesblue } from "./../../assets/svg/accessoriesblue.svg";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { ApiPath, ROOT } from "@/common/enums/apiPath";
import { Formik } from "formik";
import {
  signUpInitialValues,
  signUpSchema,
  addPostInitialValues,
  addPostSchema,
} from "@/common/validationSchemas/schemas";
import { AddPostSchemaValues } from "@/common/types/types";

import { useAppDispatch } from "@store/hooks";
import { getToken, setToken } from "@helpers/helpers";
import { setUser } from "@/store/user/slice";
import { useTranslation } from "react-i18next";
import { makeStyles, Theme } from "@mui/material/styles";

import styles from "./styles.module.scss";
import AddJetSkiForm from "@/components/addProduct/AddJetSkiForm";
import DescriptionTextField from "@/components/descriptionTextField/DescriptionTextField";
import PackageSelection from "@/components/packageSelection/PackageSelection";

interface Package {
  id: number;
  name: string;
  description: string;
  price: number;
}

const packages: Package[] = [
  { id: 1, name: "Basic Ad", description: "Basic ad description", price: 0 },
  { id: 2, name: "Pro Ad", description: "Pro ad description", price: 50 },
  {
    id: 3,
    name: "Premium Ad",
    description: "Premium ad description",
    price: 100,
  },
];

export const AddPost: React.FC = () => {
  const { t } = useTranslation();
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [postype, setPosttype] = useState(0);
  const [error, setError] = useState("");
  const isAuthenticated = getToken();
  const [selectedPackage, setSelectedPackage] = useState<Package>(packages[0]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [warranty, setWarranty] = useState("");
  const [condition, setCondition] = useState("");
  const [age, setAge] = useState("");
  const [brand, setBrand] = useState("");
  const [location, setLocation] = useState(null);

  const handleFormSubmit = async (
    values: AddPostSchemaValues,
  ): Promise<void> => {
    try {
      const value = {
        title: values.title,
        description: values.description,
        location: values.location,
      };
      const response = await fetch(`${ROOT}${ApiPath.AUTHAPI}/local/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      });

      const data = await response.json();
      if (data?.error) {
        throw data?.error;
      } else {
        setToken(data.jwt);

        dispatch(
          setUser({
            name: data.user.username,
            email: data.user.email,
            id: data.user.id,
          }),
        );

        navigate(ApiPath.PROFILE);
      }
    } catch (error: any) {
      setError(error.message);
    }
  };

  const handlePackageChange2 = (
    event: React.ChangeEvent<{ value: unknown }>,
  ): void => {
    const selectedIndex = event.target.value as number;
    setSelectedPackage(packages[selectedIndex]);
  };

  const handlePackageChange = (event: SelectChangeEvent): void => {
    const selectedIndex = parseInt(event.target.value, 10);
    if (isNaN(selectedIndex)) {
      console.error("Invalid number");
      // Handle the error or provide a default value
    } else {
      // Use selectedIndex as a number
      setSelectedPackage(packages[selectedIndex]);
    }
  };

  const handleImageChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const files = event.target.files;

    if (files) {
      const selectedFiles = Array.from(files);
      setSelectedImages((prevImages) => [...prevImages, ...selectedFiles]);
    }
  };

  const handleRemoveImage = (index: number): void => {
    setSelectedImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.splice(index, 1);
      return updatedImages;
    });
  };

  const handleButtonClick = (): void => {
    console.log("ButtonClick");
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleWarranty = (event: SelectChangeEvent): void => {
    setWarranty(event.target.value);
  };

  const handleBrand = (event: SelectChangeEvent): void => {
    setBrand(event.target.value);
  };

  const handleCondition = (event: SelectChangeEvent): void => {
    setCondition(event.target.value);
  };

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const signInText = `${t("hasAccount")}? ${t("signIn")}`;

  // if (isAuthenticated) {
  //   return <Navigate to="/" />;
  // }

  const textFieldStyle: React.CSSProperties = {
    height: "40px", // Adjust the height as needed
  };

  return (
    <Box className={styles.section}>
      <Box className={styles.pageContent}>
        <Container>
          <Box className={styles.stepbox}>
            {step === 2 ? (
              <>
                <Box className={styles.stepboxheader}>
                  <Typography variant="h4" className={styles.pageTitle}>
                    {t("newposthead3")}
                  </Typography>
                </Box>
                <Box sx={{ my: 4, marginLeft: "4%", marginRight: "4%" }}>
                  <div className="self-stretch p-12 max-md:px-5">
                    <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                      <div className="flex flex-col items-stretch w-[63%] max-md:w-full max-md:ml-0">
                        <div className="items-stretch flex flex-col max-md:max-w-full max-md:mt-10">
                          <div className="text-black text-lg font-semibold max-md:max-w-full">
                            Select a package that fit for you
                          </div>

                          {packages.map((pkg) => (
                            <div
                              onClick={() => {
                                setSelectedPackage(pkg);
                              }}
                              key={pkg.id}
                              className="border flex gap-2.5 mt-2.5 p-4 rounded-xl border-solid border-zinc-100 items-start max-md:max-w-full max-md:flex-wrap max-md:justify-center"
                            >
                              <div className="justify-center items-center border flex aspect-[1.325] flex-col pl-0.5 pr-4 py-0.5 rounded-md border-solid border-stone-300">
                                {pkg.id === selectedPackage.id ? (
                                  <img
                                    loading="lazy"
                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/9abe0c3e5142b80849c44b01a9e95fbd18bbcc9a528082865fdbd0070ceb087e?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                                    className="aspect-square object-contain object-center w-9 overflow-hidden"
                                    alt="Package Image"
                                  />
                                ) : (
                                  <img
                                    loading="lazy"
                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/7a9d47975aadd0f7debebea3d3a0936e584218b86a6883ee6e51eb46e6d201da?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                                    className="aspect-square object-contain object-center w-9 overflow-hidden"
                                    alt="Package Image"
                                  />
                                )}
                              </div>
                              <div className="self-stretch flex grow basis-[0%] flex-col pr-20 items-start max-md:max-w-full max-md:pr-5">
                                <div className="text-black text-sm font-semibold">
                                  {pkg?.name}
                                </div>
                                <div className="text-black text-sm font-light">
                                  {pkg?.description}
                                </div>
                              </div>
                              <div className="text-black text-right text-lg font-semibold self-center my-auto">
                                {pkg?.price === 0
                                  ? "Free"
                                  : "AED " + String(pkg?.price)}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="flex flex-col items-stretch w-[37%] ml-5 max-md:w-full max-md:ml-0">
                        <div className="items-stretch bg-stone-50 flex w-full flex-col mx-auto pl-8 pr-5 py-8 max-md:mt-10 max-md:pl-5">
                          <div className="text-black text-2xl font-bold">
                            Order Summary
                          </div>
                          <div className="items-stretch flex justify-between gap-5 mt-2.5 pr-6 pt-2.5 max-md:pr-5">
                            <div className="text-black text-sm font-semibold whitespace-nowrap">
                              {selectedPackage?.name}
                            </div>
                            <div className="text-black text-sm font-semibold whitespace-nowrap">
                              {selectedPackage?.price === 0
                                ? "Free"
                                : "AED " + String(selectedPackage?.price)}
                            </div>
                          </div>
                          <div className="bg-neutral-200 flex shrink-0 h-px flex-col mt-2.5" />
                          <div className="items-stretch flex justify-between gap-5 mt-2.5 pr-6 max-md:pr-5">
                            <div className="text-black text-sm whitespace-nowrap">
                              Sub Total
                            </div>
                            <div className="text-black text-sm whitespace-nowrap">
                              {"AED " + String(selectedPackage?.price)}
                            </div>
                          </div>
                          <div className="items-stretch flex justify-between gap-5 mt-2.5 pr-6 max-md:pr-5">
                            <div className="text-black text-sm whitespace-nowrap">
                              VAT - 5%
                            </div>
                            <div className="text-black text-sm whitespace-nowrap">
                              0.00
                            </div>
                          </div>
                          <div className="bg-neutral-200 flex shrink-0 h-px flex-col mt-2.5" />
                          <div className="items-stretch flex justify-between gap-5 mt-2.5 pr-6 pb-10 max-md:pr-5">
                            <div className="text-black text-sm font-semibold whitespace-nowrap">
                              Net Total
                            </div>
                            <div className="text-black text-sm font-semibold whitespace-nowrap">
                              {"AED " + String(selectedPackage?.price)}
                            </div>
                          </div>
                          {/* <div className="text-white text-xl font-semibold whitespace-nowrap justify-center items-center border bg-sky-500 mt-2.5 px-16 py-1.5 rounded-[30px] border-solid border-sky-500 max-md:px-5">
              Pay &amp; Post
            </div> */}

                          <Button
                            onClick={() => {
                              setStep(0);
                            }}
                            variant="contained"
                            color="primary"
                            sx={{
                              backgroundColor: "#17A6FF",
                              "&:hover": {
                                backgroundColor: "#D3A56B",
                              },
                            }}
                          >
                            {t("paypost")}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <PackageSelection /> */}
                  {/* <Box
                    sx={{ display: "flex", justifyContent: "center", mt: 3 }}
                  >
                    <Button
                      onClick={() => {
                        setStep(0);
                      }}
                      variant="contained"
                      color="primary"
                      sx={{
                        backgroundColor: "#17A6FF",
                        "&:hover": {
                          backgroundColor: "#D3A56B",
                        },
                      }}
                    >
                      Submit
                    </Button>
                  </Box> */}
                </Box>
              </>
            ) : step === 1 ? (
              <>
                <Box className={styles.stepboxheader}>
                  <Typography variant="h4" className={styles.pageTitle}>
                    {t("newposthead2")}
                  </Typography>
                </Box>
                <Box sx={{ my: 4, marginLeft: "4%", marginRight: "4%" }}>
                  {/* <Typography variant="h4" gutterBottom>
                  {t("newposthead1")}
                </Typography> */}

                  {/* <Typography variant="h4" className={styles.pageTitle}>
                    {t("newpost")}
                  </Typography> */}
                  {error && (
                    <Alert severity="error" sx={{ mb: 3 }}>
                      {error}
                    </Alert>
                  )}

                  <Box
                    display="flex"
                    flexDirection="row"
                    sx={{ alignItems: "center" }}
                  >
                    <Box>
                      <Paper elevation={2}>
                        <Box
                          display="flex"
                          flexDirection="row"
                          sx={{
                            p: 3,
                            marginBottom: "10px",
                            alignItems: "center",
                          }}
                        >
                          {postype === 2 ? (
                            <SVG_Accessoriesblue />
                          ) : (
                            <SVG_Jetskiblue />
                          )}
                          <Typography
                            sx={{
                              marginLeft: "10px",
                              fontSize: "18px",
                              fontWeight: "600",
                              color: "#17A6FF",
                            }}
                          >
                            {postype === 2 ? "Accessories" : "Jet ski"}
                          </Typography>
                        </Box>
                      </Paper>
                    </Box>
                    <Box sx={{ alignItems: "center", marginLeft: "10px" }}>
                      <Box>
                        <Typography
                          sx={{ fontSize: "18px", fontWeight: "600" }}
                        >
                          YOU ARE ALMOST THERE!
                        </Typography>
                        <Typography sx={{ fontSize: "14px" }}>
                          Include as much details and pictures as possible, and
                          set the right price !
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  {/* <AddJetSkiForm /> */}

                  <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={addPostInitialValues}
                    validationSchema={addPostSchema}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <TextField
                          fullWidth
                          label={t("title")}
                          name="title"
                          value={values.title}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.title && Boolean(errors.title)}
                          helperText={
                            touched.title && errors.title && t(errors.title)
                          }
                          sx={{ marginBottom: "15px" }}
                        />
                        <TextField
                          fullWidth
                          label={t("mobilenumber")}
                          name="phone"
                          value={values.phone}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.phone && Boolean(errors.phone)}
                          helperText={
                            touched.phone && errors.phone && t(errors.phone)
                          }
                          sx={{ marginBottom: "15px" }}
                        />
                        <TextField
                          fullWidth
                          name="description"
                          label={t("description")}
                          value={values.description}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={
                            touched.description && Boolean(errors.description)
                          }
                          helperText={
                            touched.description &&
                            errors.description &&
                            t(errors.description)
                          }
                          sx={{ marginBottom: "15px" }}
                          multiline
                          rows={4}
                          // style={textFieldStyle}
                        />

                        <Box
                          display={"flex"}
                          sx={{
                            border: "1px solid #888", // Border color
                            borderRadius: "8px", // Border radius
                            // outline: "2px solid #2196f3", // Outline color
                            padding: "16px", // Padding
                            marginBottom: 2,
                            justifyContent: "center",
                            alignItems: "center",
                            alignContent: "center",
                          }}
                        >
                          <input
                            type="file"
                            multiple
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={handleImageChange}
                          />

                          <IconButton onClick={handleButtonClick}>
                            <PhotoCamera />
                          </IconButton>
                          <Box>
                            {selectedImages.map((image, index) => (
                              <div
                                key={index}
                                style={{
                                  position: "relative",
                                  display: "inline-block",
                                  marginRight: 8,
                                }}
                              >
                                <IconButton
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    zIndex: 1,
                                  }}
                                  onClick={() => handleRemoveImage(index)}
                                >
                                  <CloseRounded />
                                </IconButton>
                                <img
                                  src={URL.createObjectURL(image)}
                                  alt={`Selected ${index + 1}`}
                                  style={{
                                    width: 100,
                                    height: 100,
                                    backgroundColor: "#eee",
                                    borderRadius: "10px",
                                    objectFit: "contain",
                                  }}
                                />
                              </div>
                            ))}
                          </Box>
                        </Box>

                        <Box
                          display="flex"
                          flexDirection="row"
                          sx={{
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <FormControl sx={{ marginRight: 1, flex: 1 }}>
                            <InputLabel id="demo-simple-select-autowidth-label">
                              Warranty
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-autowidth-label"
                              id="demo-simple-select-autowidth"
                              value={warranty}
                              onChange={handleWarranty}
                              autoWidth
                              label="Warranty"
                            >
                              <MenuItem value={1}>6 Months</MenuItem>
                              <MenuItem value={2}>1 Year</MenuItem>
                              <MenuItem value={3}>2 Years</MenuItem>
                              <MenuItem value={3}>3 Years</MenuItem>
                              <MenuItem value={3}>5 Years</MenuItem>
                            </Select>
                          </FormControl>

                          <FormControl sx={{ flex: 1 }}>
                            <InputLabel id="demo-simple-select-autowidth-label">
                              Brand
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-autowidth-label"
                              id="demo-simple-select-autowidth"
                              value={brand}
                              onChange={handleBrand}
                              autoWidth
                              label="Brand"
                            >
                              <MenuItem value={10}>Yamaha</MenuItem>
                              <MenuItem value={21}>Sea-Doo one</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>

                        <Box
                          display="flex"
                          flexDirection="row"
                          sx={{
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: 2,
                          }}
                        >
                          <FormControl sx={{ flex: 1 }}>
                            <InputLabel id="demo-simple-select-autowidth-label">
                              Condition
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-autowidth-label"
                              id="demo-simple-select-autowidth"
                              value={warranty}
                              onChange={handleWarranty}
                              autoWidth
                              label="Warranty"
                            >
                              <MenuItem value={1}>New</MenuItem>
                              <MenuItem value={2}>Used</MenuItem>
                              <MenuItem value={3}>Refurbished</MenuItem>
                            </Select>
                          </FormControl>

                          <FormControl
                            sx={{ marginLeft: 1, marginRight: 1, flex: 1 }}
                          >
                            <InputLabel id="demo-simple-select-autowidth-label">
                              Age
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-autowidth-label"
                              id="demo-simple-select-autowidth"
                              value={brand}
                              onChange={handleBrand}
                              autoWidth
                              label="Brand"
                            >
                              <MenuItem value={1}>1 Month</MenuItem>
                              <MenuItem value={2}>2 Months</MenuItem>
                              <MenuItem value={3}>3 Months</MenuItem>
                              <MenuItem value={4}>4 Months</MenuItem>
                              <MenuItem value={5}>5 Months</MenuItem>
                              <MenuItem value={6}>9 Months</MenuItem>
                              <MenuItem value={7}>1 Year</MenuItem>
                              <MenuItem value={8}>2 Years</MenuItem>
                              <MenuItem value={9}>3 Years</MenuItem>
                              <MenuItem value={10}>4 Years</MenuItem>
                              <MenuItem value={11}>5 Years</MenuItem>
                            </Select>
                          </FormControl>

                          <TextField
                            fullWidth
                            name="location"
                            label={t("location")}
                            value={values.location}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={touched.location && Boolean(errors.location)}
                            helperText={
                              touched.location &&
                              errors.location &&
                              t(errors.location)
                            }
                            sx={{ flex: 1 }}
                          />
                        </Box>

                        <Button
                          onClick={() => {
                            setStep(2);
                          }}
                          className={styles.button}
                          sx={{
                            backgroundColor: "#17A6FF",
                            "&:hover": {
                              backgroundColor: "#D3A56B",
                            },
                          }}
                          type="submit"
                        >
                          {t("next")}
                        </Button>
                      </form>
                    )}
                  </Formik>
                  {/* <Grid container className={styles.linksContainer}>
                    <Grid item>
                      <Link to={ApiPath.SIGNIN} className={styles.link}>
                        {signInText}
                      </Link>
                    </Grid>
                  </Grid> */}

                  {/* <Box
                    sx={{ display: "flex", justifyContent: "center", mt: 3 }}
                  >
                    <Button
                      onClick={() => {
                        setStep(2);
                      }}
                      variant="contained"
                      color="primary"
                    >
                      Continue
                    </Button>
                  </Box> */}
                </Box>
              </>
            ) : (
              <>
                <Box className={styles.stepboxheader}>
                  <Typography variant="h4" className={styles.pageTitle}>
                    {t("newposthead1")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    my: 4,
                    margin: "8%",
                    // marginTop: "8%",
                    // marginBottom: "8%",
                    // marginLeft: "8%",
                    // marginRight: "8%",
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Button
                        onClick={() => {
                          setPosttype(1);
                          setStep(1);
                        }}
                        sx={{
                          p: 3,
                          backgroundColor: "#EFCF6A",
                          "&:hover": {
                            backgroundColor: "#D3A56B",
                          },
                          padding: "50px",
                          borderRadius: "10px",
                        }}
                      >
                        <Box>
                          <SVG_Jetski />

                          <Typography variant="h6">Jet ski</Typography>
                        </Box>
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Button
                        onClick={() => {
                          setPosttype(2);
                          setStep(1);
                        }}
                        sx={{
                          p: 3,
                          backgroundColor: "#EFCF6A",
                          "&:hover": {
                            backgroundColor: "#D3A56B",
                          },
                          padding: "50px",
                          borderRadius: "10px",
                        }}
                      >
                        <Box>
                          <SVG_Accessories />
                          <Typography variant="h6">
                            Jet ski Accessories
                          </Typography>
                        </Box>
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </>
            )}
          </Box>
        </Container>
      </Box>
    </Box>
  );
};
