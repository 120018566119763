import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import { Link, useNavigate } from "react-router-dom";
import {
  AppBar,
  Badge,
  Box,
  Container,
  Divider,
  Drawer,
  IconButton,
  MenuItem,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { setIsCartOpen } from "@store/cart/slice";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { MiniCart } from "../miniCart/miniCart";
import { MiniHeader } from "../header2/miniHeader";
import { ApiPath } from "@/common/enums/apiPath";
import { fetchUser, logout } from "@store/user/slice";
import { getToken, removeToken } from "@/helpers/helpers";
import { AvatarMenu } from "../avatarMenu/AvatarMenu";
import { SearchBar } from "@/components/header/searchBar/SearchBar";
import { LanguageSwitcher } from "./languageSwitcher/LanguageSwitcher";
import { useTranslation } from "react-i18next";
import { useIsMobile, useScrollBlock } from "@/hooks/hooks";

import styles from "./styles.module.scss";
import { LocationSwitcher } from "./locationSwitcher/LocationSwitcher";

export const Header = (): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.data);
  const cart = useAppSelector((state) => state.cart.cart);
  const { blockScroll, allowScroll } = useScrollBlock();
  const navigate = useNavigate();
  const { isMobile } = useIsMobile(900);

  const authToken = getToken();

  const leftMenuPages = [
    {
      label: t("jetski"),
      path: "/category/Jet Ski",
    },
    {
      label: t("accessories"),
      path: "/category/Accessories",
    },
    {
      label: t("apparel"),
      path: "/category/Apparel",
    },
  ];
  const rightMenuPages = [
    {
      label: t("events"),
      path: "/events",
    },
    {
      label: t("community"),
      path: "/communities",
    },
    {
      label: t("blog"),
      path: "/blogs",
    },
  ];

  const topLefttMenuPages = [
    {
      label: t("notifications"),
      path: "/notifications",
    },
    {
      label: t("favourites"),
      path: "/favourites",
    },
  ];

  const [menuOpened, setMenuOpened] = useState(false);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setMenuOpened(!menuOpened);
    if (menuOpened) {
      allowScroll();
    } else {
      blockScroll();
    }
  };

  const handleLogout = (): void => {
    removeToken();
    dispatch(logout());
    navigate("/");
  };

  useEffect(() => {
    if (authToken) {
      void dispatch(fetchUser(authToken));
    }
  }, [authToken]);

  useEffect(() => {
    if (!isMobile) setMenuOpened(false);
  }, [isMobile]);

  const MyToolbar = styled(Toolbar)(({ theme }) => ({
    "@media (min-width: 600px)": {
      minHeight: "48px",
    },
  }));

  return (
    <>
      <AppBar
        position="sticky"
        sx={{
          height: "45px",
          minHeight: "45px",
          zIndex: 10,
        }}
      >
        <Container>
          <MyToolbar disableGutters={true} className={styles.toolbar}>
            <Box
              className={styles.leftCol}
              sx={{ display: { xs: "none", md: "block", gap: "10px" } }}
            >
              {/* <div className="items-stretch mr-5 border flex justify-between gap-1 px-4 py-1 rounded-3xl border-solid border-white cursor-pointer  transition-all duration-300 ease-in-out hover:bg-yellow-500">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/517cbcd01e8cb1ee643c2f87400902abbd58a4777c02e2f4ca9702792711ca03?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                  className="aspect-square object-contain object-center w-5 overflow-hidden shrink-0 max-w-full"
                />
                <div className="text-white text-center text-base font-semibold grow whitespace-nowrap self-start">
                  Abu Dhabi
                </div>
              </div> */}

              <LocationSwitcher className={styles.locationSwitcherDark} />

              <LanguageSwitcher className={styles.locationSwitcherDark} />
              {/* <div className={styles.menu}>
                  {leftMenuPages.map(({ label, path }) => (
                    <Link to={path} className="link" key={label}>
                      <Typography>{label}</Typography>
                    </Link>
                  ))}
                </div> */}
            </Box>
            <Box
              sx={{
                width: { sm: "33.33%" },
                display: { xs: "flex", md: "none" },
              }}
            >
              <IconButton
                size="large"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                {menuOpened ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
            </Box>
            {/* <div className={styles.centerCol}>
                <Link to="/" className={styles.siteLogo}>
                  <Typography
                    variant="h3"
                    component="h1"
                    className={styles.siteLogoText}
                  >
                    Yifeenah
                  </Typography>
                </Link>
              </div> */}
            <div className={styles.rightCol}>
              <Box sx={{ display: { xs: "none", md: "block" } }}>
                <div className={styles.menu}>
                  {topLefttMenuPages.map(({ label, path }) => (
                    <Link to={path} className="link" key={label}>
                      <Typography sx={{ color: "#fff" }}>{label}</Typography>
                    </Link>
                  ))}
                </div>
              </Box>
              <div className={styles.icons}>
                <Box sx={{ display: { xs: "none", md: "block" } }}>
                  {!user.name && (
                    <Link to={ApiPath.SIGNIN} className={styles.link}>
                      <PersonOutlineIcon />
                    </Link>
                  )}
                  {user.name && (
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      sx={{ alignItems: "center" }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          marginRight: "5px",
                          marginLeft: "10px",
                        }}
                      >
                        Hi, {user.name}
                      </Typography>
                      <AvatarMenu />
                    </Box>
                  )}
                </Box>
              </div>
            </div>
          </MyToolbar>
        </Container>
      </AppBar>
      <MiniHeader />
      <Drawer
        anchor="top"
        variant="persistent"
        open={menuOpened}
        onClose={handleOpenNavMenu}
        className={styles.drawer}
        sx={{
          zIndex: 30,
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": {
            width: "100%",
            height: "100%",
            top: "45px",
            zIndex: "9",
          },
        }}
      >
        {leftMenuPages.map(({ label, path }) => (
          <Link to={path} className="link" key={label}>
            <MenuItem onClick={handleOpenNavMenu}>
              <Typography textAlign="center">{label}</Typography>
            </MenuItem>
          </Link>
        ))}
        {rightMenuPages.map(({ label, path }) => (
          <Link to={path} className="link" key={label}>
            <MenuItem onClick={handleOpenNavMenu}>
              <Typography textAlign="center">{label}</Typography>
            </MenuItem>
          </Link>
        ))}
        <Divider />
        <Link to={ApiPath.PROFILE} className={styles.link}>
          <MenuItem>{t("profile")}</MenuItem>
        </Link>
        <MenuItem onClick={handleLogout}>{t("logout")}</MenuItem>
        <Divider />
        <LanguageSwitcher className={styles.langSwitcherDark} />
      </Drawer>
      {/* <MiniCart /> */}
    </>
  );
};
