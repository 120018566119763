import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import clsx from "clsx";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { setLocation } from "@store/settings/slice";
import { useTranslation } from "react-i18next";
import { useIsMobile } from "@/hooks/hooks";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import styles from "./styles.module.scss";

import { createTheme, ThemeProvider } from "@mui/material/styles";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

export const LocationSwitcher = ({
  className,
}: {
  className?: string;
}): React.ReactElement => {
  const dispatch = useAppDispatch();
  const location = useAppSelector((state) => state.settings.location);
  const { i18n } = useTranslation();
  const { isMobile } = useIsMobile(900);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // const handleChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
  //   const selectedLanguage = event.target.value;
  //   localStorage.setItem("language", selectedLanguage);
  //   dispatch(setLanguage(selectedLanguage));
  //   void i18n.changeLanguage(selectedLanguage);
  //   setLanguage(selectedLanguage);
  //   setIsDropdownOpen(false); // Close the dropdown after selection
  // };

  const handleChange = (event: SelectChangeEvent<string>): void => {
    const selectedLocation = event.target.value;
    localStorage.setItem("language", selectedLocation);
    dispatch(setLocation(selectedLocation));
    // void i18n.changeLanguage(selectedLocation);
    setLocation(selectedLocation);
  };

  const handleMobileChange = (loc: string): void => {
    localStorage.setItem("location", loc);
    dispatch(setLocation(loc));
    // void i18n.changeLanguage(loc);
  };

  useEffect(() => {
    const localLocation = localStorage.getItem("location");
    if (localLocation) {
      dispatch(setLocation(localLocation));
    }
  }, []);

  return (
    <>
      {isMobile ? (
        <Box className={styles.mobileContainer}>
          {/* <MenuItem onClick={() => handleMobileChange("en")}>EN</MenuItem>
          <MenuItem onClick={() => handleMobileChange("ar")}>AR</MenuItem> */}

          <MenuItem
            onClick={() => handleMobileChange("abudhabi")}
            value={"abudhabi"}
          >
            Abu Dhabi
          </MenuItem>
          <MenuItem onClick={() => handleMobileChange("ajman")} value={"ajman"}>
            Ajman
          </MenuItem>
          <MenuItem onClick={() => handleMobileChange("dubai")} value={"dubai"}>
            Dubai
          </MenuItem>
          <MenuItem
            onClick={() => handleMobileChange("fujairah")}
            value={"fujairah"}
          >
            Fujairah
          </MenuItem>
          <MenuItem
            onClick={() => handleMobileChange("rasalkhaimah")}
            value={"rasalkhaimah"}
          >
            Ras Al Khaimah
          </MenuItem>
          <MenuItem
            onClick={() => handleMobileChange("sharjah")}
            value={"sharjah"}
          >
            Sharjah
          </MenuItem>
          <MenuItem
            onClick={() => handleMobileChange("ummalquwain")}
            value={"ummalquwain"}
          >
            Umm Al Quwain
          </MenuItem>
        </Box>
      ) : (
        // <div className="relative inline-block">
        //   <div
        //     className="items-stretch border flex justify-between gap-1 px-4 py-1 rounded-3xl border-solid border-white cursor-pointer"
        //     onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        //   >
        //     <img
        //       loading="lazy"
        //       src="https://cdn.builder.io/api/v1/image/assets/TEMP/cd0eb8f7bb2fd12221d0e42df0c72b58c9d69730b305d4d62e62c153d040baa6?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
        //       className="aspect-square object-contain object-center w-5 overflow-hidden shrink-0 max-w-full"
        //     />
        //     <div className="text-white text-center text-base font-semibold grow whitespace-nowrap self-start">
        //       {language === "en" ? "English" : "Arabic"}
        //     </div>
        //   </div>
        //   <select
        //     className={`absolute ${
        //       isDropdownOpen ? "block" : "hidden"
        //     } bg-white mt-2 py-2 px-3 rounded shadow-md z-100`}
        //     onChange={handleChange}
        //     onBlur={() => setIsDropdownOpen(false)}
        //     value={language}
        //   >
        //     <option value="en">English</option>
        //     <option value="ar">Arabic</option>
        //   </select>
        // </div>
        // <Select
        //   defaultValue={language}
        //   value={language}
        //   onChange={handleChange}
        //   variant="standard"
        //   IconComponent={() => <KeyboardArrowDownIcon />}
        //   className={clsx(className, styles.select, styles.customSelect)}
        //   sx={{ borderColor: "#fff", borderWidth: "1px", borderRadius: "16px" }}
        // >
        //   <MenuItem value="en">EN</MenuItem>
        //   <MenuItem value="ar">AR</MenuItem>
        // </Select>

        // <Select
        //   value={language}
        //   onChange={handleChange}
        //   variant="standard"
        //   IconComponent={() => <KeyboardArrowDownIcon />}
        //   className={clsx(className, styles.select, styles.customSelect)}
        //   sx={{
        //     borderColor: "#fff",
        //     borderWidth: "1px",
        //     paddingLeft: "8px",
        //     paddingRight: "8px",
        //     borderRadius: "16px",
        //     borderBottomColor: "#fff",
        //     "&.MuiSelect-root": {
        //       borderBottom: "none", // Override the default border style
        //     },
        //     "&.css-aocdpi-MuiInputBase-root-MuiInput-root-MuiSelect-root:before":
        //       {
        //         borderBottom: "none", // Override the default border style
        //       },
        //   }}
        //   inputProps={{
        //     id: "language-select",
        //     classes: {
        //       notchedOutline: "MuiInput-underline", // This removes the default border
        //     },
        //   }}
        // >
        //   <MenuItem value="en">English</MenuItem>
        //   <MenuItem value="ar">Arabic</MenuItem>
        // </Select>
        <ThemeProvider theme={darkTheme}>
          <FormControl sx={{ m: 0, minWidth: 120 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
                borderColor: "#fff",
                borderWidth: "1px",
                borderRadius: "16px",
                height: "36px",
              }}
            >
              <LocationOnIcon
                style={{
                  color: "#fff",
                  position: "absolute",
                  left: "8px",
                }}
              />
              <Select
                value={location}
                onChange={handleChange}
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOnIcon style={{ color: "#fff" }} />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  color: "#fff",
                  borderColor: "#fff",
                  borderWidth: "1px",
                  paddingLeft: "16px",
                  paddingRight: "8px",
                  borderRadius: "16px",
                  borderBottomColor: "#fff",
                  height: "36px",
                }}
              >
                {/* Abu Dhabi, Dubai, Sharjah, Ajman, Umm Al Quwain, Ras Al Khaimah and Fujairah */}
                <MenuItem value={"abudhabi"}>Abu Dhabi</MenuItem>
                <MenuItem value={"ajman"}>Ajman</MenuItem>
                <MenuItem value={"dubai"}>Dubai</MenuItem>
                <MenuItem value={"fujairah"}>Fujairah</MenuItem>
                <MenuItem value={"rasalkhaimah"}>Ras Al Khaimah</MenuItem>
                <MenuItem value={"sharjah"}>Sharjah</MenuItem>
                <MenuItem value={"ummalquwain"}>Umm Al Quwain</MenuItem>
              </Select>
            </div>
          </FormControl>
        </ThemeProvider>

        // <FormControl variant="standard" sx={{ flexDirection: "row" }}>
        //   <InputLabel
        //     htmlFor="language-select"
        //     shrink={false}
        //     style={{ display: "none" }}
        //   >
        //     Language
        //   </InputLabel>
        //   <Select
        //     label="Language"
        //     value={language}
        //     onChange={handleChange}
        //     IconComponent={() => <KeyboardArrowDownIcon />}
        //     inputProps={{
        //       id: "language-select",
        //       classes: {
        //         notchedOutline: "MuiInput-underline", // This removes the default border
        //       },
        //     }}
        //     className={clsx(className, styles.select, styles.customSelect)}
        //     sx={{
        //       borderColor: "#fff",
        //       borderWidth: "1px",
        //       paddingLeft: "8px",
        //       paddingRight: "8px",
        //       borderRadius: "16px",
        //       borderBottomColor: "#fff",
        //       marginTop: "-8px",
        //     }}
        //   >
        //     <MenuItem value="en">English</MenuItem>
        //     <MenuItem value="ar">Arabic</MenuItem>
        //   </Select>
        // </FormControl>
      )}
    </>
  );
};
